import axios, { Axios, AxiosRequestConfig } from 'axios';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AppProject, ConversationSession, Index, Integration, MetaType, SearchResult, SupportConversationInfo, TopicInfo } from './models/dataModel';
import { TaskExecution, TaskRecipe, TaskWorkflow } from './components/WorkflowDebuging/workflowsSchema';

let api: ApiService = null;
function getApi(): ApiService {



  if (!(api)) {
    const auth = getAuth();
    onAuthStateChanged(auth, (currentUser) => {
      if (api) {
        api.currentUser = currentUser;
      }
    });

    api = new ApiService(process.env.REACT_APP_API_URL || "http://localhost:4080")//, auth.currentUser);

    //api = new ApiService("http://localhost:4000/", auth.currentUser);
  }

  return api
}



class ApiService {
  baseUrl: string;

  currentUser: any;
  axiosInstance: Axios;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;

    this.axiosInstance = axios.create();

    this.axiosInstance.interceptors.request.use(
      async (config) => {
        if (!(api && api.currentUser)) await this.tryWaitForAuth(10);
        if (api && api.currentUser) {
          let token = await api.currentUser.getIdToken();
          let loginOnAccountOfUser = localStorage.getItem(
            "loginOnAccountOfUser"
          );
          if (loginOnAccountOfUser) {
            token = `${token}>>${loginOnAccountOfUser}`;
          }

          if (token && config && config.headers) {
            config.headers["authorization"] = "Bearer " + token;
          }
        }
        config.headers["Content-Type"] = "application/json";

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    this.axiosInstance.interceptors.response.use(undefined, (error) => {
      if (error.response && error.response.status === 403) {
        if (
          !window.location.href.includes("/login") &&
          !window.location.href.includes("/signup")
        ) {
          setTimeout(() => {
            window.location.href = "/login?force=true";
          }, 500);
        }
        return Promise.reject(error.response);
      } else return Promise.reject(error);
    });
  }

  async tryWaitForAuth(countdown: number) {
    if (!this.currentUser && countdown > 0) {
      await new Promise((r) => setTimeout(r, 200)); //Wait 1s for auth
      if (!this.currentUser) {
        await this.tryWaitForAuth(countdown - 1);
      }
    }
  }


  async authorize(): Promise<{ "Authorization"?: string }> {
    if (!(api && api.currentUser)) await this.tryWaitForAuth(10);
    if (api && api.currentUser) {
      let token = await api.currentUser.getIdToken();
      let loginOnAccountOfUser = localStorage.getItem("loginOnAccountOfUser");
      if (loginOnAccountOfUser) {
        token = `${token}>>${loginOnAccountOfUser}`;
      }
      let headers = {};
      if (token) {
        headers["Authorization"] = "Bearer " + token;
      }

      return headers;
    } else {
      throw new Error("Not authorized");
    }
  }

  async fetch(path: string, query: any, options: AxiosRequestConfig<any> = {}): Promise<any> {
    return this.axiosInstance
      .get(this.baseUrl + path, { ...(options || {}), params: query })
      .then((response) => {
        return response.data;
      });
  }

  post(path: string, data: any): Promise<any> {
    return this.axiosInstance
      .post(this.baseUrl + path, data)
      .then((response) => {
        return response.data;
      });
  }
  patch(path: string, data): Promise<any> {
    return this.axiosInstance
      .patch(this.baseUrl + path, data)
      .then((response) => {
        return response.data;
      });
  }
  delete(path: string, data: any = undefined): Promise<any> {
    return this.axiosInstance
      .delete(this.baseUrl + path, data)
      .then((response) => {
        return response.data;
      });
  }

  saveApp(entity: AppProject): Promise<AppProject> {
    return this.axiosInstance
      .post(this.baseUrl + `/management/apps`, entity)
      .then((response) => {
        return response.data;
      });
  }

  getApp(appId: string): Promise<AppProject> {
    return this.axiosInstance
      .get(this.baseUrl + `/management/apps/${appId}`)
      .then((response) => {
        return response.data;
      });
  }

  getIndex(index_id: string): Promise<Index> {
    return this.axiosInstance
      .get(this.baseUrl + `/management/indexes/${index_id}`)
      .then((response) => {
        return response.data;
      });
  }

  queryIndex(
    index_id: string,
    query: string,
    skip: number = 0,
    limit: number = 25
  ): Promise<SearchResult[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/management/indexes/${index_id}/query`, {
        params: { query, skip, limit },
      })
      .then((response) => {
        return response.data;
      });
  }

  reindexDatasource(
    appId: string,
    datasource_uuid: string,
    source_name: string = null
  ): Promise<SearchResult[]> {
    return this.axiosInstance
      .post(
        this.baseUrl +
          `/management/apps/${appId}/datasources/${datasource_uuid}/reindex`,
        { params: { source_name } }
      )
      .then((response) => {
        return response.data;
      });
  }

  getAllApps(): Promise<AppProject[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/management/apps`)
      .then((response) => {
        return response.data;
      });
  }

  deleteApp(appId: string): Promise<null> {
    return this.axiosInstance
      .delete(this.baseUrl + `/management/apps/${appId}`)
      .then((response) => {
        return response.data;
      });
  }

  getMetaSchemaTypes(entity): Promise<MetaType[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/meta/schema/${entity}`)
      .then((response) => {
        return response.data;
      });
  }
  getMetaSchema(entity, entityType): Promise<JSONSchema> {
    return this.axiosInstance
      .get(this.baseUrl + `/meta/schema/${entity}/${entityType}`)
      .then((response) => {
        return response.data;
      });
  }

  getSessions({
    appId,
    q,
    skip = 0,
    limit = 25,
    dateRangeStart,
    dateRangeEnd,
  }: {
    appId: string;
    q: string;
    dateRangeStart?: string;
    dateRangeEnd?: string;
    skip: number;
    limit: number;
  }): Promise<ConversationSession[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/${appId}/conversations/sessions`, {
        params: { q, skip, limit, dateRangeStart, dateRangeEnd },
      })
      .then((response) => {
        return response.data;
      });
  }

  getMessages(appId, session_id): Promise<any[]> {
    return this.axiosInstance
      .get(
        this.baseUrl + `/${appId}/conversations/sessions/${session_id}/messages`
      )
      .then((response) => {
        return response.data;
      });
  }

  deleteMessage(appId, session_id, message_id): Promise<null> {
    return this.axiosInstance
      .delete(
        this.baseUrl + `/${appId}/conversations/sessions/${session_id}/messages/${message_id}`
      )
      .then((response) => {
        return response.data;
      });
  }

  getTopics({
    appId,
    skip = 0,
    limit = 25,
  }: {
    appId: string;
    skip: number;
    limit: number;
  }): Promise<TopicInfo[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/${appId}/conversations/topics`, {
        params: { skip, limit },
      })
      .then((response) => {
        return response.data;
      });
  }

  getIntegrations(active: boolean = true): Promise<Integration[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/integrations/`, { params: { active } })
      .then((response) => {
        return response.data;
      });
  }

  connectIntegration(
    connector: string,
    payload: { redirect?: boolean; followup_uri?: string; credentials?: any }
  ): Promise<{ url?: string; success?: boolean; integration?: Integration }> {
    return this.axiosInstance
      .post(this.baseUrl + `/integrations/${connector}/connect`, payload)
      .then((response) => {
        return response.data;
      });
  }

  getIntegration(connector: string): Promise<Integration | undefined> {
    return this.axiosInstance
      .get(this.baseUrl + `/integrations/${connector}`)
      .then((response) => {
        return response.data;
      });
  }

  disconnectIntegration(connector: string): Promise<{ url: string }> {
    return this.axiosInstance
      .get(this.baseUrl + `/integrations/${connector}/disconnect`)
      .then((response) => {
        return response.data;
      });
  }

  patchIntegrationAccount(
    connector: string,
    account: string,
    patchPayload: { active?: boolean; primary?: boolean }
  ): Promise<any> {
    return this.axiosInstance
      .patch(
        this.baseUrl + `/integrations/${connector}/accounts/${account}`,
        patchPayload
      )
      .then((response) => {
        return response.data;
      });
  }

  patchIntegrationSettings(
    connector: string,

    patchPayload: { [key: string]: any }
  ): Promise<any> {
    return this.axiosInstance
      .patch(this.baseUrl + `/integrations/${connector}/settings`, patchPayload)
      .then((response) => {
        return response.data;
      });
  }

  connectIntegrationCallback(
    connector: string,
    queryParams
  ): Promise<{ success: boolean }> {
    return this.axiosInstance
      .get(this.baseUrl + `/integrations/${connector}/connect`, {
        params: queryParams,
      })
      .then((response) => {
        return response.data;
      });
  }

  postIntegrationAction(
    connector: string,
    actionPayload: {
      action_name: string;
      action_description: string;
      response_retriever?: {
        type: "initial" | "polling";
        response: string;
        polling_test: string;
        polling_timeout_hours?: number;
        polling_response: { [key: string]: any };
      };
      [key: string]: any;
    }
  ): Promise<any> {
    return this.axiosInstance
      .post(this.baseUrl + `/integrations/${connector}/action`, actionPayload)
      .then((response) => {
        return response.data;
      });
  }

  postIntegrationDatasource(
    connector: string,
    actionPayload: {
      action_name: string;
      action_description: string;
      [key: string]: any;
    }
  ): Promise<any> {
    return this.axiosInstance
      .post(
        this.baseUrl + `/integrations/${connector}/datasource`,
        actionPayload
      )
      .then((response) => {
        return response.data;
      });
  }

  testIntegrationAction(
    connector: string,
    actionPayload: {
      action_name: string;
      action_description: string;
      [key: string]: any;
    },
    args: { [key: string]: any }
  ): Promise<any> {
    return this.axiosInstance
      .post(
        this.baseUrl + `/integrations/${connector}/test-run`,
        actionPayload,
        { params: args }
      )
      .then((response) => {
        return response.data;
      });
  }

  addIntegrationDatasource(
    connector: string,
    dataSourcePayload: {
      action_name: string;
      action_description: string;
      [key: string]: any;
    }
  ): Promise<any> {
    return this.axiosInstance
      .post(
        this.baseUrl + `/integrations/${connector}/datasource`,
        dataSourcePayload
      )
      .then((response) => {
        return response.data;
      });
  }

  deleteIntegrationAction(connector: string, actionId: string): Promise<any> {
    return this.axiosInstance
      .delete(this.baseUrl + `/integrations/${connector}/action/${actionId}`)
      .then((response) => {
        return response.data;
      });
  }

  deleteIntegrationDatasource(
    connector: string,
    datasourceId: string
  ): Promise<any> {
    return this.axiosInstance
      .delete(
        this.baseUrl + `/integrations/${connector}/datasource/${datasourceId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  getSupportSessions(
    limit = 0,
    skip = 100
  ): Promise<SupportConversationInfo[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/support/sessions`, { params: { limit, skip } })
      .then((response) => {
        return response.data;
      });
  }

  getSupportSessionsMessages(sessionId: string): Promise<any[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/support/sessions/${sessionId}/messages`)
      .then((response) => {
        return response.data;
      });
  }

  getSupportSessionsTools(sessionId: string): Promise<{
    commands: { [functionName: string]: JSONSchema };
    datasources: {
      name: string;
      datasource_id: string;
      filters: JSONSchema | null;
    }[];
  }> {
    return this.axiosInstance
      .get(this.baseUrl + `/support/sessions/${sessionId}/tools`)
      .then((response) => {
        return response.data;
      });
  }

  supportSessionsDatasourceSearch(
    sessionId: string,
    datasourceId: string,
    queryText: string,
    filter: { [key: string]: any }
  ): Promise<{ success: boolean }> {
    return this.axiosInstance
      .post(
        this.baseUrl +
          `/support/sessions/${sessionId}/datasources/${datasourceId}/search`,
        filter,
        { params: { query_text: queryText } }
      )
      .then((response) => {
        return response.data;
      });
  }

  supportSessionsExecuteAction(
    sessionId: string,
    actionId: string,
    args: { [key: string]: any }
  ): Promise<{ success: boolean }> {
    return this.axiosInstance
      .post(
        this.baseUrl +
          `/support/sessions/${sessionId}/commands/${actionId}/execute`,
        args
      )
      .then((response) => {
        return response.data;
      });
  }

  handleLookup(app_id, datasource_id, lookup_value) {
    return this.axiosInstance
      .get(this.baseUrl + `/${app_id}/datasources/${datasource_id}/lookup`, {
        params: { q: lookup_value },
      })
      .then((response) => {
        return response.data;
      });
  }

  getTasks(filter: {
    q?: string;
    complex_task?: boolean;
    active?: boolean;
    app_id?: string;
    [key: string]: any;
  }): Promise<any[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks`, { params: filter })
      .then((response) => {
        return response.data;
      });
  }

  getTask(id: string): Promise<any> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks/${id}`)
      .then((response) => {
        return response.data;
      });
  }

  getWorkflows(
    q: string,
    tenant_id: string = undefined
  ): Promise<TaskWorkflow[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks/workflows`, { params: { q, tenant_id } })
      .then((response) => {
        return response.data;
      });
  }
  getLastTaskWorkflow(task_id: string): Promise<TaskWorkflow> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks/${task_id}/workflows/last`)
      .then((response) => {
        return response.data;
      });
  }
  getTaskWorkflowVersions(task_id: string): Promise<TaskWorkflow[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks/${task_id}/workflows/versions`)
      .then((response) => {
        return response.data;
      });
  }
  getTasWorkflowExecutions(task_id: string): Promise<TaskExecution[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks/${task_id}/workflows/executions`)
      .then((response) => {
        return response.data;
      });
  }

  getWorkflow(id: string): Promise<TaskWorkflow> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks/workflows/${id}`)
      .then((response) => {
        return response.data;
      });
  }
  getWorkflowExecutions(id: string): Promise<TaskWorkflow> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks/workflows/${id}`)
      .then((response) => {
        return response.data;
      });
  }

  getWorkflowExecutionsCheckPointState(
    taskId: string,
    executionId: string
  ): Promise<any> {
    return this.axiosInstance
      .get(
        this.baseUrl +
          `/tasks/${taskId}/workflows/executions/${executionId}/checkpoint-state`
      )
      .then((response) => {
        return response.data;
      });
  }

  getWorkflowExecutionLogs(
    taskId: string,
    executionId: string
  ): Promise<
    {
      step_name: string;
      severity: string;
      log_message: string;
      state_changes: any;
      step_exec_info?: {
        progress_index?: number;
        progress_total?: number;
        status?: string;
        status_message?: string;
      };
    }[]
  > {
    return this.axiosInstance
      .get(
        this.baseUrl +
          `/tasks/${taskId}/workflows/executions/${executionId}/logs`
      )
      .then((response) => {
        return response.data;
      });
  }

  patchWorkflowExecutionsCheckPointState(
    taskId: string,
    executionId: string,
    updatePatch: { [key: string]: any }
  ): Promise<any> {
    return this.axiosInstance
      .patch(
        this.baseUrl +
          `/tasks/${taskId}/workflows/executions/${executionId}/checkpoint-state`,
        updatePatch
      )
      .then((response) => {
        return response.data;
      });
  }

  postWorkflow(workflowToSave: TaskWorkflow): Promise<TaskWorkflow> {
    return this.axiosInstance
      .post(
        this.baseUrl + `/tasks/${workflowToSave.task_id}/workflows`,
        workflowToSave
      )
      .then((response) => {
        return response.data;
      });
  }

  postRecipe(recipe: TaskRecipe): Promise<TaskRecipe> {
    return this.axiosInstance
      .post(this.baseUrl + `/workflow-recipes`, recipe)
      .then((response) => {
        return response.data;
      });
  }

  updateRecipe(recipeId: string, recipe: TaskRecipe): Promise<TaskRecipe> {
    return this.axiosInstance
      .post(this.baseUrl + `/workflow-recipes/${recipeId}`, recipe)
      .then((response) => {
        return response.data;
      });
  }

  deleteRecipe(recipeId: string): Promise<TaskRecipe> {
    return this.axiosInstance
      .delete(this.baseUrl + `/workflow-recipes/${recipeId}`)
      .then((response) => {
        return response.data;
      });
  }

  getClosestRecipes(task_id): Promise<TaskRecipe[]> {
    return this.axiosInstance
      .get(this.baseUrl + `/tasks/${task_id}/closest-recipes`)
      .then((response) => {
        return response.data;
      });
  }

  runTaskWorkflow(
    task_id: string,
    workflow_id: string = undefined,
    force: boolean = false
  ): Promise<TaskWorkflow> {
    return this.axiosInstance
      .post(this.baseUrl + `/tasks/${task_id}/run`, null, {
        params: { workflow_id, force },
      })
      .then((response) => {
        return response.data;
      });
  }

  resumeTaskExecution(
    task_id: string,
    execution_id: string = undefined
  ): Promise<TaskWorkflow> {
    return this.axiosInstance
      .post(
        this.baseUrl + `/tasks/${task_id}/executions/${execution_id}/resume`,
        null
      )
      .then((response) => {
        return response.data;
      });
  }

  scheduleTask(
    task_id: string,
    schedule: {
      recurring: boolean;
      postponed: boolean;
      next_run?: Date | null;
      recurring_frequency?: string | null;
      recurring_frequency_cron?: string | null;
    }
  ): Promise<TaskWorkflow> {
    return this.axiosInstance
      .post(this.baseUrl + `/tasks/${task_id}/schedule`, schedule)
      .then((response) => {
        return response.data;
      });
  }

  analyzeWorkflowCode(
    task_id: string,
    task_workflow_id: string,
    code: string,
    taskInputs: { [key: string]: any } = undefined
  ): Promise<{
    valid: boolean;
    feedback: {
      is_error: boolean;
      lineno: number;
      step_name: string;
      text: string;
    }[];
    steps: any[];
  }> {
    let url =
      this.baseUrl + `/tasks/${task_id}/workflows/${task_workflow_id}/analyze`;
    if (!task_workflow_id) {
      url = this.baseUrl + `/tasks/${task_id}/workflows/analyze`;
    }
    return this.axiosInstance
      .post(url, {
        workflow_code: code,
        task_inputs: taskInputs,
      })
      .then((response) => {
        return response.data;
      });
  }

  patchAccountInfo(
    accountId,
    payload: {
      active?: boolean;
      role?: string;
      name?: string;
      email?: string;
      new_password?: string;
    }
  ): Promise<null> {
    return this.axiosInstance
      .patch(this.baseUrl + `/org/accounts/${accountId}`, payload)
      .then((response) => {
        return response.data;
      });
  }

  getMyAccount(): Promise<Account> {
    return this.axiosInstance
      .get(this.baseUrl + "/org/my-account")
      .then((response) => {
        if (response.status < 300) {
          return response.data;
        }
        throw new Error(response.statusText);
      });
  }

  getAccounts(q: string = undefined, rootMode = undefined): Promise<Account[]> {
    let params = {};
    if (q) {
      params = { q };
    }
    if (rootMode) {
      params = { ...params, rootMode };
    }
    return this.axiosInstance
      .get(this.baseUrl + "/org/accounts", { params })
      .then((response) => {
        return response.data;
      });
  }
  getAccount(id: string, rootMode = undefined): Promise<Account> {
    let params: any = {
      id,
    };
    if (rootMode) {
      params = { ...params, rootMode: rootMode };
    }
    return this.axiosInstance
      .get(this.baseUrl + `/org/accounts`, {
        params,
      })
      .then((response) => {
        return response.data[0];
      });
  }

  passwordReset(
    email,
    payload: {
      verification_code: string;
      new_password: string;
    } = undefined
  ): Promise<null> {
    return this.axiosInstance
      .post(this.baseUrl + "/org/password-reset", payload, {
        params: { email },
      })
      .then((response) => {
        return response.data;
      });
  }

  signup(
    payload: {
      email: string;
      password?: string;
      account_id?: string;
      invite_id?: string;
    } = undefined
  ): Promise<null> {
    return this.axiosInstance
      .post(this.baseUrl + "/org/signup", payload)
      .then((response) => {
        return response.data;
      });
  }

  createInvite(
    payload: {
      email: string;
      new_tenant?: boolean;
      role: string;
    } = undefined
  ): Promise<null> {
    return this.axiosInstance
      .post(this.baseUrl + "/org/invites", payload)
      .then((response) => {
        return response.data;
      });
  }

  getInvites(): Promise<
    { email: string; id: string; role: string; tenant_id; sent_by_tenant }[]
  > {
    return this.axiosInstance
      .get(this.baseUrl + "/org/invites")
      .then((response) => {
        return response.data;
      });
  }

  revokeInvite(id: string): Promise<null> {
    return this.axiosInstance
      .delete(this.baseUrl + "/org/invites/" + id)
      .then((response) => {
        return response.data;
      });
  }

  testInvite(
    email: string,
    verification: {
      invite_id?: string;
      invite_verification_code?: string;
    }
  ): Promise<{
    invite_id: string;
    tenant: string;
    valid_until: string;
    sent_by: string;
    join_tenant: boolean;
  }> {
    return this.axiosInstance
      .get(this.baseUrl + "/org/invites/test", {
        params: { email, ...verification },
      })
      .then((response) => {
        return response.data;
      });
  }

  getMyOrganizationInfo(): Promise<{
    name: string;
    tenant_id: string;
    plan: string;
    active_until: string;
  }> {
    return this.axiosInstance
      .get(this.baseUrl + "/org/info")
      .then((response) => {
        return response.data;
      });
  }

  managePlan(plan: string): Promise<null> {
    return this.axiosInstance
      .patch(this.baseUrl + "/org/plan/manage", { plan })
      .then((response) => {
        return response.data;
      });
  }

  checkoutSession(
    plan: string,
    followup_url: string
  ): Promise<{ checkout_session_url }> {
    return this.axiosInstance
      .post(this.baseUrl + "/org/plan/activate", null, {
        params: { plan, followup_url },
      })
      .then((response) => {
        return response.data;
      });
  }

  checkoutSessionFinish(checkout_session_id: string): Promise<{}> {
    return this.axiosInstance
      .post(this.baseUrl + "/org/plan/activate/finish", null, {
        params: { checkout_session_id },
      })
      .then((response) => {
        return response.data;
      });
  }

  requestSupport(message: string): Promise<{}> {
    return this.axiosInstance
      .post(this.baseUrl + "/org/manage/support", { message })
      .then((response) => {
        return response.data;
      });
  }

  getBillingInfo(): Promise<BillingInfo> {
    return this.axiosInstance
      .get(this.baseUrl + "/org/manage/billing-info")
      .then((response) => {
        return response.data;
      });
  }

  // getBillingCountries(
  //   ): Promise<string[]> {
  //     return this.axiosInstance.get(this.baseUrl + "/org/manage/billing-info/countries")
  //     .then((response) => {
  //       return response.data;
  //     });
  //   }

  updateBillingInfo(billingInfoData: BillingInfo): Promise<null> {
    return this.axiosInstance
      .patch(this.baseUrl + "/org/manage/billing-info", billingInfoData)
      .then((response) => {
        return response.data;
      });
  }

  getInvoices(): Promise<InvoiceInfo[]> {
    return this.axiosInstance
      .get(this.baseUrl + "/org/billing/invoices")
      .then((response) => {
        return response.data;
      });
  }

  getInvoice(invoice_id: string): Promise<{ url: string }> {
    return this.axiosInstance
      .get(this.baseUrl + `/org/billing/invoices/${invoice_id}`)
      .then((response) => {
        return response.data;
      });
  }

  getUpcomingInvoice(): Promise<InvoiceInfo> {
    return this.axiosInstance
      .get(this.baseUrl + "/org/billing/invoices/upcoming")
      .then((response) => {
        return response.data;
      });
  }
}
    

export {getApi}
