import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { PageDefinition } from '../model';
import FlowPageProps from './pageProps';
import { AutoUI } from 'geniously-chat-ui';
import { Box, Checkbox, HStack, Image, Stack, Text } from '@chakra-ui/react';
import FlowPageWrapper from './pageWrapper';
import { GeniouslyThemeProvider } from 'geniously-chat-ui';
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'
import { exitCode } from 'process';
import { IconCircleCheck, IconCircleCheckFilled } from '@tabler/icons-react';
import { IconCircle } from '@tabler/icons-react';

interface SharedState {
    selectedSections?: string[];
    editedForms?: { [key: string]: any };
}

// Create the context
const SharedStateContext = createContext<{
    state: SharedState;
    setState: React.Dispatch<React.SetStateAction<SharedState>>;
} | undefined>(undefined);

// Custom hook to use the shared state context
const useSharedState = () => {
    const context = useContext(SharedStateContext);
    if (!context) {
        throw new Error('useSharedState must be used within a SharedStateProvider');
    }
    return context;
};


const MarkdownContent = ({ children, customComponents, ...rest }: {
    children: string,
    customComponents?: any,
    [key: string]: any
}) => {

    const components = {
        ...(customComponents || {}),
        a: ({ children, href, ...rest }: any) => {
            return <a href={href} target="_blank" rel="noreferrer">{children}</a>
        }

    }

    return (<Box

        {...rest}
        fontWeight={500}
        whiteSpace="initial"
        css={` 
                
                h1{
                    font-size: 1.6em;
                    font-weight: 800;
                    margin: 10px 0px 0px;
                    
                    
                }
                h2{
                    font-size: 1.2em;
                    font-weight: 700;
                      margin: 8px 0px 0px;
                }
                h3{
                    font-size: 1em;
                    font-weight: 600;
                }
                    h4,h5,h6{
                    font-size: 0.9em;
                    font-weight: 600;
                }
                p,b,span {
                    white-space: wrap;
                    font-size: inherit;
                    font-family:  "Segoe UI", "Arial", sans-serif;
                    margin: 8px 0px;
                    font-weight: 500;
                }
                & li {margin-left: 15px;
                             
                    font-family:   "Segoe UI", "Arial", sans-serif;
                }
                & ol > li {
                    list-style-type: decimal;
                }
                & ul > li {
                    list-style-type: initial;
                }
                & a {
                    color: #0078d4;
                    text-decoration: underline;
                    cursor: pointer;
                }
             
                `}
    >




        <ReactMarkdown

            remarkPlugins={[remarkBreaks, remarkGfm]}
            components={components}
        >
            {children}
        </ReactMarkdown>



    </Box >)
}


const SelectableSection = ({ value }: { value: { id: string, title: string, description: string, allowMultiselect: boolean, image: string } }) => {
    const { state, setState } = useSharedState();

    const checked = state && state.selectedSections && state.selectedSections.includes(value.id)

    const handleCheckboxChange = () => {
        setState(prevState => {
            const isSelected = prevState?.selectedSections?.includes(value.id);
            let newSelectedSections;

            if (isSelected) {
                newSelectedSections = prevState?.selectedSections?.filter(id => id !== value.id);
            } else {
                if (value.allowMultiselect) {
                    newSelectedSections = [...(prevState?.selectedSections || []), value.id];
                } else {
                    newSelectedSections = [value.id];
                }
            }

            return {
                ...(prevState || {}),
                selectedSections: newSelectedSections,
            };
        });
    };

    return (
        <HStack align="start" m="6px 0px" borderRadius={4} _hover={{ backgroundColor: "whitesmoke" }} cursor="pointer" p="2px" onClick={handleCheckboxChange}>
            <Stack flexShrink={0} color={checked ? "dodgerblue" : undefined} align="start" justify="start" p="8px 2px">
                {checked ? <IconCircleCheckFilled size="20px" /> : <IconCircle size="20px" />}
            </Stack>

            <HStack fontWeight={500} fontSize="sm" fontFamily="sans-serif" border={checked ? "1px solid lightskyblue" : "1px dashed lightgray"}
                align="start"
                backgroundColor={checked ? "rgba(230 230 250 / 0.26)" : undefined}
                borderRadius={4} flexGrow={1} p="4px 4px">
                {
                    value?.image && <Stack flexShrink={0} color={checked ? "dodgerblue" : undefined} align="start" justify="start" height="30px" width="30px" border="1px solid whitesmoke" borderRadius={4} overflow="hidden">
                        <Image src={value?.image} fallback={<></>} />
                    </Stack>
                }
                <Stack spacing={0} ml="4px">

                    <Text m="0px !important" fontSize="16pt !important" fontWeight={900}>{value.title}</Text>

                    <MarkdownContent>
                        {value.description}
                    </MarkdownContent>
                </Stack>
            </HStack>
        </HStack>
    );
};

const FormSection = ({ value }: { value: { id: string, title?: string, data: any, data_schema: JSONSchema } }) => {
    const { state, setState } = useSharedState();

    const handleFormChange = (newData: any) => {
        setState(prevState => ({
            ...prevState,
            editedForms: {
                ...(prevState?.editedForms || {}),
                [value.id]: newData
            }
        }));
    };
    const formValue = state?.editedForms && state?.editedForms[value.id] ? state?.editedForms[value.id] : (value.data || {})

    return (
        <Stack fontFamily="sans-serif" m="6px 0px">
            <Text m="0px !important" fontSize="16pt !important" fontWeight={900}>{value.title}</Text>
            <GeniouslyThemeProvider>
                <AutoUI size="xl" value={formValue} schema={value.data_schema} onValueChange={handleFormChange} showOnlySchemaFields />
            </GeniouslyThemeProvider>
        </Stack>
    );
};

const WidgetSection = ({ children }: any) => {

    let childrenParsed = useMemo(() => {
        try {
            return JSON.parse(children)
        }
        catch {
            return
        }
    }, [children])
    const type = childrenParsed?.type;
    switch (type) {
        case "selectable":
            return <SelectableSection value={childrenParsed} />;
        case "form":
            return <FormSection value={childrenParsed} />;
        default:
            return <Stack p="20px" backgroundColor="gray.100"><Text whiteSpace="pre">{children?.toString()}</Text></Stack>;
    }
};

const codeQuotes = "```"





const CanvasFlowPage: React.FC<FlowPageProps> = (props: FlowPageProps) => {


    //     const value = (`# Test
    // Hi

    // ${codeQuotes}
    //     {"id":"1","type":"selectable", "title":"Test", "description":"Test\\ntest **with bold**", "allowMultiselect":true, "image":"http://www.microsoft.com/favicon.ico"}
    // ${codeQuotes}
    // ${codeQuotes}
    //     {"id":"2","type":"selectable", "title":"Test", "description":"Test\\ntest **with bold**", "allowMultiselect":true}
    // ${codeQuotes}
    // ${codeQuotes}
    //     {"id":"3","type":"selectable", "title":"Test", "description":"Test\\ntest **with bold**", "allowMultiselect":true}
    // ${codeQuotes}

    // ${codeQuotes}
    //     {
    //         "id": "2",
    //         "type": "form",
    //         "title": "Company Details",
    //         "description": "Please fill out the form with your company details.",
    //         "schema": {
    //             "type": "object",
    //             "properties": {
    //                 "companyName": {
    //                     "type": "string",
    //                     "title": "Company Name",
    //                     "example": "Your Company Inc."
    //                 },
    //                 "companySize": {
    //                     "type": "number",
    //                     "title": "Number of Employees",
    //                     "example": 100
    //                 }
    //             },
    //             "required": ["companyName", "companySize"]
    //         }
    //     }
    // ${codeQuotes}

    //         `);

    const value = useMemo(() => {
        var sections = props.page.canvas?.sections
        return sections?.map(s => typeof (s) === "string" ? s : "```\n" + JSON.stringify(s) + "\n```").join("\n\n")
    }, [props.currentPageState, props.page.canvas.sections])



    return (
        <SharedStateContext.Provider value={{ state: props.currentPageState || props.page.canvas, setState: props.setCurrentPageState }}>
            <FlowPageWrapper pageProps={props}>
                <Stack p="10px">

                    <MarkdownContent
                        customComponents={{
                            code: WidgetSection
                        }}>
                        {value}
                    </MarkdownContent>
                </Stack>
            </FlowPageWrapper>
        </SharedStateContext.Provider>
    );
};


export default CanvasFlowPage;