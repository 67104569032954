import React, { useEffect, useMemo, useState } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Stack,
    Heading,
    HStack,
    CloseButton,
    Text,
    IconButton,
} from '@chakra-ui/react'
import { ConfirmModal } from '../ModalDialogs/confirmModal';
import { CampaignSteps } from './campaignSteps';
import CampaignStagePreview from './campaignStagePreview';
import STEP_TYPES from './stepTypes';
import { ListProvider } from './listContext';
import { AutoDatePicker, GeniouslyThemeProvider } from 'geniously-chat-ui';
import { CampaignProvider } from './campaignContexts';
import { CampaignEdit } from './campaignEdit';
import { IconArrowsDiagonalMinimize, IconCheck, IconChevronRight, IconEdit, IconPlayerPlayFilled, IconRefresh, IconWindowMaximize, IconWindowMinimize } from '@tabler/icons-react';
import { IconArrowsDiagonal2 } from '@tabler/icons-react';
import { RunOpWithUpdatedFiltersDialog } from '../CustomDialogs/RunOpWithUpdatedFiltersDialog';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Radio, RadioGroup } from "@chakra-ui/react";

const CampaignBuilder = ({ campaign, onOk, onCancel, error }: {
    campaign?: Campaign;
    error?: string
    onOk?: (campaign: Campaign, options?: { updateMode: "updateFilters" | "preserveFilters" | "preserveSelection" }) => void;
    onCancel?: () => void;

}) => {
    const [modal, setModal] = React.useState<React.ReactNode>(null)
    const [activeStepId, setActiveStepId] = React.useState()

    function onCancelWithConfirm() {
        if (campaign?.state === "draft" || edited) {
            setModal(<ConfirmModal
                question='Are you sure you want to cancel?'
                okButtonLabel='Yes'
                cancelButtonLabel='No (continue editing)'
                caption={campaign?.state === "draft" ? 'Cancel Campaign creation' : "Cancel changes"}
                onOk={() => onCancel()}
                onCancel={() => setModal(null)}
            />)
        }
        else {
            onCancel()
        }

    }

    const [campaignState, setCampaignState] = React.useState<Campaign>(campaign || {
        name: "",
        state: "draft",
        steps: []
    })

    const [errors, setErrors] = React.useState<any>({})
    const [editMode, setEditMode] = React.useState(true)
    const [minimized, setMinimized] = React.useState(false)

    const [pendingOk, setPendingOk] = React.useState(false)
    const readOnly = !editMode && campaign?.state !== "draft"
    const edited = useMemo(() => editMode && JSON.stringify(campaignState) !== JSON.stringify(campaign), [editMode, campaignState, campaign])
    useEffect(() => {
        campaign.state == "closed" && setEditMode(false)
    }, [campaign])
    const [activeTab, setActiveTab] = React.useState<"settings" | "sequence">()
    const selectedStep = useMemo(() => campaignState?.steps?.find((step) => !readOnly && step.step_id === activeStepId), [campaignState, activeStepId])
    return (
        minimized ? <HStack position="fixed" p="5px 10px" bottom="10px" left="10px" rounded={8} border="2px solid gray" shadow="2xl"
            cursor="pointer"
            background="white" zIndex={10} onClick={() => setMinimized(false)}>
            <Text fontSize="xl" fontWeight={500}>{campaign.state == "draft" ? "Create campaign" : (editMode ? "Edit campaign" : "View campaign")}</Text>

            <IconWindowMaximize size="20px" />
        </HStack> :
            <Drawer
                isOpen={true}
                placement='right'
                onClose={() => onCancelWithConfirm()}
                closeOnOverlayClick={false}
                size={activeStepId ? "2xl" : "md"}



            >
                <DrawerOverlay />
                <DrawerContent maxW={selectedStep ? ["100vw", "100vw", "100vw", "100vw", "1200px"] : "500px"}>

                    <DrawerCloseButton />
                    <HStack></HStack>


                    <DrawerBody p="0px" >


                        <CampaignProvider campaign={campaignState} onUpdateCampaign={setCampaignState} onErrorsChanged={setErrors}>
                            {modal}
                            <HStack justify="end" height="100%" flexGrow={1}>
                                {selectedStep && <Stack width="100%" height="100%" backgroundColor="gray.50" p="0px">
                                    <Stack height="100%" >

                                        <HStack h="50px" justify="space-between" p="20px">

                                            <Heading size="md">{selectedStep.stage_name || `Step #${campaignState.steps.findIndex((s) => s === selectedStep) + 1}`}</Heading>



                                            <CloseButton onClick={() => setActiveStepId(undefined)} />

                                        </HStack>
                                        <Stack height="100%" backgroundColor="gray.50" borderRadius="10px" p="10px">

                                            <CampaignStagePreview campaignStage={selectedStep} onChange={(val) => {
                                                setCampaignState({
                                                    ...campaignState, steps: campaignState.steps.map(step => step.step_id === selectedStep.step_id ? val : step)
                                                })

                                            }} />
                                        </Stack>
                                        <DrawerFooter>

                                        </DrawerFooter>
                                    </Stack>

                                </Stack>

                                }
                                <Stack shadow="2xl">
                                    <HStack h="50px" justify="space-between" p="20px" mr="20px">

                                        <Heading size="md" fontWeight={900}>{campaign?.state ? "Create campaign" : (editMode ? "Edit campaign" : "Campaign")}</Heading>
                                        < IconButton justifySelf="end" variant="ghost" icon={<IconWindowMinimize size="20px" />} aria-label={"Minimize"} onClick={() => setMinimized(true)} />

                                    </HStack>
                                    <Stack height="calc(100vh - 140px)" flexGrow={1} w="500px" backgroundColor="gray.50" overflow="auto">
                                        <Stack p="10px" flexGrow={1}>
                                            <CampaignEdit
                                                readOnly={readOnly}
                                                activeStepId={activeStepId}
                                                activeTab={activeTab}
                                                onActiveTabChange={(tab) => {
                                                    setActiveTab(tab)
                                                }}
                                                onActiveStepIdChange={(activeStepId) => {
                                                    setActiveStepId(activeStepId)
                                                }}
                                                campaign={campaignState} onChange={(newVal) => {
                                                    setCampaignState(newVal)
                                                }} />
                                        </Stack>
                                        {error && <Stack position="sticky" bottom="0px" p="8px 15px" maxH="3.5em" flexShrink={0} overflow="auto" color="maroon" background="pink.100"><Text>{error}</Text></Stack>}
                                    </Stack>
                                    <DrawerFooter>


                                        {!editMode && <Button colorScheme='brand' p="10px"
                                            leftIcon={<IconEdit size={20} />}
                                            position="absolute"
                                            left={5}
                                            justifySelf="start" mr={3} onClick={() => setEditMode(!editMode)} >
                                            Edit
                                        </Button>}

                                        <Button variant='outline' mr={3} onClick={onCancelWithConfirm}>
                                            Cancel
                                        </Button>

                                        {(campaign?.state == "draft") && <Button colorScheme='brand'
                                            isLoading={pendingOk}
                                        //isLoading={true}
                                            leftIcon={(activeTab === "sequence" ? <IconChevronRight /> : <IconPlayerPlayFilled />)}
                                            onClick={() => {

                                                if (errors && Object.keys(errors).length > 0) {
                                                    setModal(<ConfirmModal
                                                        question='There some errors in the campaign'
                                                        caption='Campaign validation'
                                                        onOk={() => {

                                                            setModal(null)
                                                        }}

                                                    >
                                                        <Stack>
                                                            {Object.keys(errors).map((key) => <Text color="tomato">{errors[key]}</Text>)}
                                                        </Stack>
                                                    </ConfirmModal>)
                                                }
                                                else if (activeTab === "sequence") {
                                                    setActiveTab("settings")
                                                }
                                                else {
                                                    setPendingOk(true)
                                                    let okRes = onOk(campaignState) as any
                                                    if (okRes && okRes.finally) {
                                                        okRes.finally(() => setPendingOk(false))
                                                    }
                                                    else {
                                                        setPendingOk(false)
                                                    }
                                                }
                                            }
                                            }>
                                            {activeTab === "sequence" ? "Next" : "Confirm & Launch"}
                                        </Button>}

                                        {(campaign?.state != "draft" && editMode) && <Button colorScheme='brand'
                                            isLoading={pendingOk}
                                            leftIcon={(!edited ? <IconRefresh /> : <IconCheck />)}
                                            onClick={() => {
                                                if (errors && Object.keys(errors).length > 0) {
                                                    setModal(<ConfirmModal
                                                        question='There some errors in the campaign'
                                                        caption='Campaign validation'
                                                        onOk={() => {

                                                            setModal(null)
                                                        }}

                                                    >
                                                        <Stack>
                                                            {Object.keys(errors).map((key) => <Text color="tomato">{errors[key]}</Text>)}
                                                        </Stack>
                                                    </ConfirmModal>)
                                                }
                                                else {


                                                    setModal(<UpdateModeDialog
                                                        onClose={() => setModal(null)}
                                                        onConfirm={(updateMode) => {
                                                            setModal(null)
                                                            setPendingOk(true)
                                                            let okRes = onOk(campaignState, { updateMode }) as any
                                                            if (okRes && okRes.finally) {
                                                                okRes.finally(() => {

                                                                    setPendingOk(false)
                                                                })
                                                            }
                                                            else {

                                                                setPendingOk(false)
                                                            }
                                                        }}

                                                    />

                                                    )


                                                }
                                            }
                                            }>
                                            {(edited ? "Confirm & Update" : "Update")}
                                        </Button>}


                                    </DrawerFooter>
                                </Stack>
                            </HStack>
                        </CampaignProvider>

                    </DrawerBody>

                </DrawerContent>
            </Drawer>
    );
};

function UpdateModeDialog({ onClose, onConfirm, caption }: {
    caption?: string,

    onClose?: () => void,
    onConfirm?: (updateMode: "updateFilters" | "preserveFilters" | "preserveSelection") => void
}) {
    const [selectedOption, setSelectedOption] = useState<"updateFilters" | "preserveFilters" | "preserveSelection">("updateFilters");

    return (
        <AlertDialog
            isOpen={true}
            onClose={onClose}
            leastDestructiveRef={undefined}
            size="3xl"
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {caption || "Update campaign"}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <RadioGroup onChange={val => setSelectedOption(val as any)} value={selectedOption}>
                            <Stack direction="column">

                                <Radio value="updateFilters">
                                    <Stack spacing={0}>
                                        <Text>

                                            Update leads to match current view
                                        </Text>
                                        <Text fontSize="xs">Leads not included in current view will be canceled, leads not included yet will be added</Text>
                                    </Stack>
                                </Radio>




                                <Radio value="preserveFilters">
                                    <Stack spacing={0}>
                                        <Text>

                                            Update with original filters
                                        </Text>
                                        <Text fontSize="xs">Original filters will pre preserved, regardless of current view. If new rows will match the original filter, the will be added. If some data were modified that cause some rows to drop out of the filter, the will be canceled</Text>
                                    </Stack>
                                </Radio>

                                <Radio value="preserveSelection">
                                    <Stack spacing={0}>
                                        <Text>

                                            Update existing leads only
                                        </Text>
                                        <Text fontSize="xs">Original selection will be preserved, regardless of current view. No new leads will added or removed</Text>
                                    </Stack>
                                </Radio>
                            </Stack>
                        </RadioGroup>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="brand" onClick={() => onConfirm(selectedOption)} ml={3}>
                            Confirm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

export default CampaignBuilder;