

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, MenuGroup, Portal, Progress, SimpleGrid, Spinner, Stack, Switch, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";


const ReRunOptions = ({ optionsValue, setOptionsValue }: {
    optionsValue: { newRecords: boolean, emptyAndFailed: boolean, allRecords: boolean, override_filter_current_view?: boolean },
    setOptionsValue: (value: any) => void
}) => {

    return <Stack>
        <Stack spacing={0}>
            <Checkbox borderRadius="full" isChecked={!!optionsValue["newRecords"]} onChange={(e) => {
                setOptionsValue({ override_filter_current_view: optionsValue.override_filter_current_view, "newRecords": e.target.checked })
            }}>New records</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">Records that have been added later, or record that have been filtered out by the view</Text>
        </Stack>
        <Stack spacing={0}>
            <Checkbox borderRadius="full" isChecked={!!optionsValue["emptyAndFailed"]} onChange={(e) => {
                setOptionsValue({ override_filter_current_view: optionsValue.override_filter_current_view, "emptyAndFailed": e.target.checked })
            }}>Empty and failed</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">This includes all records that don't have value (relevant for column operations)</Text>
        </Stack>
        <Stack spacing={0}>
            <Checkbox borderRadius="full" isChecked={!!optionsValue["allRecords"]} onChange={(e) => {
                setOptionsValue({ override_filter_current_view: optionsValue.override_filter_current_view, "allRecords": e.target.checked })
            }}>All records</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">All current values (overwrite existing)</Text>
        </Stack>
        <Stack>
            <Text fontWeight={600}>Filter override</Text>
            <Stack spacing={0}>
                <HStack>

                    <Switch isChecked={!!optionsValue["override_filter_current_view"]} onChange={(e) => {
                        setOptionsValue({ ...(optionsValue || {}), "override_filter_current_view": e.target.checked })
                    }} />
                    <Text>{optionsValue["override_filter_current_view"] ? "Override filter with current view" : "Use original filter defined on the operation"}</Text>
                </HStack>
                <Text ml="40px" fontStyle="italic" fontSize="xs">Whether to run the operation on the same subset of rows as originally defined, or wether to temporarily override the defined filters and execute on {optionsValue["allRecords"] ? <b>all</b> : "the"} visible rows {optionsValue["allRecords"] ? "." : <b>{optionsValue["emptyAndFailed"] ? "that are empty or failed" : (optionsValue["newRecords"] ? "that are new" : "where operation has not been executed yet")}</b>}</Text>
            </Stack>
        </Stack>
    </Stack>
}

export default ReRunOptions;