
import { useEffect, useMemo, useState } from "react";

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Spinner,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag, WrapItem, Wrap, Link, ButtonGroup, Button, Td, Tbody, Th, Tr, Thead, TableCaption, Table, TableContainer, Tooltip } from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';



import { Box, HStack, Stack, Text } from "@chakra-ui/react";

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

import ContactCard from "../Inbox/ContactCard";
import { IconAlertTriangle, IconAlertTriangleFilled, IconBold, IconBolt, IconCalendarTime, IconCheckbox, IconChevronDown, IconChevronUp, IconCircleDashedX, IconExternalLink, IconLink, IconMailCheck, IconSelector, IconTable } from "@tabler/icons-react";
import { useApiEndpoint, useApiFetch } from "../../utils/useApiHook";
import { AutoUI, GeniouslyThemeProvider } from "geniously-chat-ui";
import ContactDetail from "../Inbox/ContactDetail";
import { ActivityCard } from "../Inbox/activitiesCards";
import { ContactInbox } from "../Inbox/inbox";
import PagingComponent from "../Tables/pagingComponent";


interface ContactStatsStep {
    campaign_step_id: number;
    sent_at?: string;
    scheduled_send_time?: Date;
    error?: number;
    archived_reason?: string;
}

interface ContactStats {
    contact_id: string;
    accepted_invite: boolean;
    replied: boolean;
    replied_first_timestamp?: Date;
    last_timestamp?: Date;
    next_scheduled?: Date;
    steps: ContactStatsStep[];
    campaign_id: string;
    account: string;
    contact_name?: string;
    contact_status?: string | null;
    version?: string;
}

interface StageStats {
    stage?: string;
    campaign_step_id?: number;
    count: number;
    errors?: number | null;
    replies?: number | null;
    archived?: number | null;
}

interface AccountsStats {
    account?: string;
    count: number;
    errors?: number | null;
    replies?: number | null;
    by_statuses?: Record<string, number> | null;
}

interface CampaignStats {
    tenant_id?: string;
    campaign_id?: string;
    contacts_count?: number;
    contacted?: number;
    canceled?: number;

    accepted_invite?: number;
    replied?: number;
    by_statuses?: Record<string, number> | null;
    last_timestamp?: Date;
    stages?: StageStats[];
    accounts?: AccountsStats[];
}

function getCampaignStatsId(campaignStats: CampaignStats): string {
    return `${campaignStats.tenant_id}|${campaignStats.campaign_id}`;
}


const CampaignDetail = ({ selectedCampaign }: { selectedCampaign: { stats: CampaignStats, [key: string]: any } }) => {
    const bulkUpdateEndpoint = useApiEndpoint("DELETE", "/inbox/contacts/activities/messages/bulk_archive_scheduled")
    //const contactsEndpoint = useApiFetch<any[]>(`/analytics/campaigns/${selectedCampaign.id}/contacts`, { swrOptions: { revalidateIfStale: false, refreshInterval: 990000, revalidateOnFocus: false } })
    const [sortBy, setSortBy] = useState<string | null>()
    const [page, setPage] = useState(1);
    const contactsEndpoint = useApiFetch<ContactStats[]>(`/analytics/campaigns/${selectedCampaign.id}/stats-by-contacts?sort=${sortBy}&skip${(page - 1) * 100}&take=100`, { swrOptions: { revalidateIfStale: false, refreshInterval: 990000, revalidateOnFocus: false } })

    useEffect(() => setPage(1), [selectedCampaign])
    //const [fetchMessagesType, setMessagesType] = useState<string>()
    //const messagesEndpoint = useApiFetch<any[]>(`/analytics/campaigns/${selectedCampaign.id}/messages/${fetchMessagesType}`, { swrOptions: { revalidateIfStale: false, refreshInterval: 990000, revalidateOnFocus: false, shouldFetch: !!fetchMessagesType } })
    const [activeTab, setActiveTab] = useState(0)
    const [modal, setModal] = useState<any>()

    useEffect(() => {
        setActiveTab(0)
        //setMessagesType(undefined)

    }, [selectedCampaign])
    // useEffect(() => {
    //     if (activeTab == 1) {
    //         setMessagesType("sent")
    //     }
    // }, [activeTab])
    const colors = {
        "sent": "#0099ff",
        "error": "#ff5050",
        "archived": "#5D667A",
        "replied": "#00cc66",
        "accepted": "rgb(0 221 255)",
    }


    const chartData = useMemo(() => {

        if (selectedCampaign) {
            let res = selectedCampaign?.steps?.map((step) => {
                let matched_step = selectedCampaign.stats?.stages?.find(s => s.stage == step.stage_name) || { stage: step.stage_name } as any
                matched_step.type = step.step_type
                if (matched_step) {
                    matched_step.sent = matched_step.count - (matched_step.errors || 0) - (matched_step.archived || 0)
                    //_res.error_percent = Math.round(100 * ((_res.errors as any) || 0) / selectedCampaign.contacts_count)
                    if (matched_step.type == "linkedin-connect") {
                        matched_step.accepted_invite = selectedCampaign.stats?.accepted_invite

                    }
                }

                return matched_step

            })

            if (res)
                res.reverse()

            let series = [
                {
                    name: 'Total',
                    type: 'bar',
                    stack: 'total',
                    color: "gainsboro",
                    label: {
                        show: true,

                        formatter: (d) => d.data + " (total)"
                    },

                    barWidth: 12,
                    data: res?.map((step) => step.count || 0)
                },
                {
                    name: 'Sent',
                    type: 'bar',
                    stack: 'types',
                    color: colors["sent"],
                    label: {
                        show: true,
                        fontWeight: 900,
                        fontSize: "14px"
                    },

                    data: res?.map((step) => step.sent || undefined)
                }
                ,

                {
                    name: 'Failed',
                    type: 'bar',
                    stack: 'types',
                    color: colors["error"],
                    label: {
                        show: true
                    },
                    barWidth: 20,
                    data: res?.map((step) => step.errors || undefined)
                },
                {
                    name: 'Archived',
                    type: 'bar',
                    stack: 'types',
                    color: colors["archived"],
                    label: {
                        show: true

                    },
                    barWidth: 20,
                    data: res?.map((step) => step.archived || undefined)
                },

                {
                    name: 'Accepted',
                    type: 'bar',
                    stack: 'reaction',
                    color: colors["accepted"],
                    label: {
                        show: true,
                        color: "black",
                        formatter: (d) => `${d.data} ☑︎`,
                        fontWeight: 900,
                        fontSize: "14px"

                    },
                    barWidth: 15,
                    data: res?.map((step) => step.accepted_invite || undefined),

                },
                {
                    name: 'Replied',
                    type: 'bar',
                    stack: 'reaction',
                    color: colors["replied"],
                    label: {
                        show: true,
                        color: "black",
                        fontWeight: 900,
                        formatter: (d) => `${d.data} ↩︎`,
                        fontSize: "14px"
                    },
                    barWidth: 15,
                    data: res?.map((step) => step.replies || undefined),


                },


            ]
            let cat = selectedCampaign?.steps?.map(s => s.stage_name)
            cat?.reverse()
            return { series, yCategories: cat }
        }
    }, [selectedCampaign])






    function bulkCancelMessages(type: "not-engaged" | "all") {
        let payload = { campaign_id: selectedCampaign.id }
        if (type == "not-engaged") {
            payload["campaign_contact_state_filter"] = "scheduled"
        }
        else {
            payload["close_campaign"] = true
        }
        bulkUpdateEndpoint.execute({}, payload)
    }

    return selectedCampaign && <Stack align="stretch" justify="stretch" height="100%" maxH="calc(100vh - 20px)" width="calc(100vh - 50px)" flexGrow={1}  >
        <HStack justify="space-between" p="10px" width="100%" flexShrink={1}>
            <Text fontSize="lg">{selectedCampaign.name}</Text>
            {/* <Text>{JSON.stringify(selectedCampaign)}</Text> */}
            <HStack justify="end">

                <Menu >
                    <Link href={`${window.location.origin}/lists/${selectedCampaign.list_id}`}>
                        <MenuButton size="sm" as={Button} leftIcon={<IconExternalLink size="20px" color="gray" />}>Open list</MenuButton>
                    </Link>    <MenuButton size="sm" as={Button} leftIcon={<IconBolt />} rightIcon={<IconChevronDown />} isLoading={bulkUpdateEndpoint.isRunning}>
                        Actions
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => bulkCancelMessages("not-engaged")}>Cancel campaign for not engaged</MenuItem>
                        <MenuItem onClick={() => bulkCancelMessages("all")}>Cancel campaign for all</MenuItem>

                    </MenuList>
                </Menu>
            </HStack >
        </HStack>
        <HStack align="start" overflow="auto" height="40%">
            {modal}
            <Stack flexGrow={1} flexShrink={1} maxH="40vh" overflow="hidden">
                {selectedCampaign.steps && <ReactECharts
                    style={{
                        height: '30vh',
                        width: 'calc(100% - 150px)',
                    }}
                    option={{
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                // Use axis to trigger tooltip
                                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                            }
                        },
                        legend: {},
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true // Ensure labels fit within the grid
                        },

                        xAxis: {
                            type: 'value',
                            show: false

                        },
                        yAxis: {
                            type: 'category',
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: (val) => {
                                    const displayVal = val.length > 20 ? `${val.slice(0, 20)}...` : val;
                                    return `{a|Step #${chartData.yCategories.length - chartData.yCategories?.indexOf(val)}}\n${displayVal}`;
                                },

                                rich: {
                                    a: {

                                        fontWeight: 600
                                    },
                                }
                            },
                            data: chartData.yCategories
                        },
                        series: chartData.series
                    }}

                    //notMerge={true}
                    lazyUpdate={true}
                //theme={"theme_name"}
                //onChartReady={this.onChartReadyCallback}

                //opts={ }
                />
                }
                {/* {selectedCampaign.steps && <ReactECharts
                style={{
                    height: '50vh',
                    width: '50vw',
                }}
                option={{
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    legend: {},

                    xAxis: {
                        type: 'value',
                        show: false

                    },
                    yAxis: {
                        type: 'category',
                        axisLine: {
                            show: false
                        },
                        data: ['LinkedIn Connection Message', 'Follow-up Message'] //chartData?.map((step) => step.stage)
                    },


                    series: [
                        {
                            name: 'Sent',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: [10, 20]//chartData?.map((step) => step.count || 0)
                        },
                    ]
                }}

                //notMerge={true}
                lazyUpdate={true}
                //theme={"theme_name"}
                //onChartReady={this.onChartReadyCallback}

            //opts={ }
            />
            } */}
            </Stack>
            <Stack p="0px 20px" >

                <HStack justify="end" >
                    <Text fontWeight={500}>Total contacts:</Text>
                    <Tag><Text fontWeight={900}>{selectedCampaign.stats?.contacts_count}</Text></Tag>
                </HStack >
                <HStack justify="end" >
                    <Text fontWeight={500}>Contacted:</Text>
                    <Tag>
                        <Text fontWeight={900}>{selectedCampaign.stats?.contacted || 0}</Text>
                    </Tag>
                </HStack >
                <HStack justify="end" >
                    <Text fontWeight={500}>Canceled:</Text>
                    <Tag>
                        <Text fontWeight={900}>{selectedCampaign.stats?.canceled}</Text>
                    </Tag>
                </HStack >
                {selectedCampaign.stats?.accepted_invite && <HStack justify="end">
                    <Text fontWeight={500}>Accepted invite:</Text>
                    <Tag><Text fontWeight={900}>{selectedCampaign.stats?.accepted_invite}</Text></Tag>
                </HStack>}

                <HStack justify="end">
                    <Text fontWeight={500}>Replied:</Text>
                    <Tag><Text fontWeight={900}>{selectedCampaign.stats?.replied}</Text></Tag>
                </HStack>
                {selectedCampaign.stats?.by_statuses &&
                    <>
                        <Text>Statuses:</Text>

                        {Object.keys(selectedCampaign.stats?.by_statuses).map(k => (
                            <HStack justify="end" fontSize="12px">
                                <Text fontWeight={500} >{k}</Text>
                                <Tag><Text fontWeight={900}>{selectedCampaign.stats?.by_statuses[k]}</Text></Tag>
                            </HStack>
                        ))}
                    </>
                }
            </Stack>
        </HStack>
        <Stack className="bottomPanel" flexGrow={1} width="100%" height="60%" flexShrink={1} >
            <Tabs width="100%" index={activeTab} height="100%" flexShrink={1} onChange={setActiveTab}>
                <TabList>
                    <Tab>Contacts</Tab>
                    <Tab>Outreach accounts</Tab>
                    {/* <Tab>Messages</Tab> */}

                </TabList>

                <TabPanels height="calc(100% - 30px)" >
                    <TabPanel overflow="auto" height="100%" flexShrink={1}>
                        <Stack overflow="auto" flexShrink={1} flexGrow={1} justify="space-between" height="100%" minH="100px">
                            {contactsEndpoint?.isLoading ? (
                                <Spinner />
                            ) : <ContactsTable
                                data={contactsEndpoint.data}
                                stage_names={selectedCampaign?.steps?.map(s => s.stage_name)}
                                sortBy={sortBy}
                                onSortChange={setSortBy}
                                onContactClick={(contactId) => setModal(<ContactDrawer campaignId={selectedCampaign.id} contactId={contactId} onClose={() => setModal(undefined)} />)}
                            />}
                            <PagingComponent pageNo={page} totalCount={selectedCampaign?.stats?.contacts_count || 0} onPageChange={setPage} />

                            {/* // <Wrap align="stretch" alignItems="stretch">
                            //     {contactsEndpoint.isLoading ? (<Text>Loading...</Text>) : (
                            //         (contactsEndpoint.data?.map((contact, index) => {
                            //             return (
                            //                 <WrapItem width="400px" >
                            //                     <Stack key={index} width="100%" height="100%" >
                            //                         <Link href={"/inbox/" + contact.contact_id}>
                            //                             <ContactCard contact={contact.contact} height="100%">
                            //                                 <HStack>

                            //                                     <Tag fontSize="xs" fontWeight={500}>{contact.current_stage || "no action yet"}</Tag>
                            //                                     <Tag fontSize="xs" fontWeight={500} backgroundColor={colors[contact.state]}>{contact.state}</Tag>
                            //                                 </HStack>


                            //                             </ContactCard>
                            //                         </Link>
                            //                     </Stack>
                            //                 </WrapItem>
                            //             )
                            //         }))
                            //     )}
                            // </Wrap>
                            // {contactsEndpoint.data?.length == 100 && <Stack><Text>Showing only first 100</Text></Stack>} */}
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <AccountsTable data={selectedCampaign?.stats?.accounts} />
                    </TabPanel>
                    {/* <TabPanel width="100%">
                        <Stack >
                            <HStack alignSelf="end">
                                <ButtonGroup size='sm' isAttached colorScheme="red">
                                    <Button variant={fetchMessagesType == "sent" ? "solid" : "outline"} onClick={() => setMessagesType("sent")}>Sent</Button>
                                    <Button variant={fetchMessagesType == "scheduled" ? "solid" : "outline"} onClick={() => setMessagesType("scheduled")} >Scheduled next</Button>
                                    <Button variant={fetchMessagesType == "received" ? "solid" : "outline"} onClick={() => setMessagesType("received")}>Received</Button>

                                </ButtonGroup>
                            </HStack>
                            <Stack overflow="auto" maxH="35vh">
                                {messagesEndpoint.isLoading ? (<Text>Loading...</Text>) : (
                                    (messagesEndpoint.data?.map((message, index) => {
                                        return (
                                            <Link href={"/inbox/" + message.contact_id}>
                                                <Stack key={index} width="100%" height="100%" spacing={1} border="1px solid gray" p="4px" rounded={2} borderRadius={4}
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                        //navigate()
                                                    }}
                                                >
                                                    <HStack justify="space-between">
                                                        {message.subject ? <Text>Subject: {message.subject}</Text> : <Text></Text>}
                                                        <Text justifySelf="end" fontSize="xs" fontWeight={500} color="gray" textAlign="end"><Moment>{message.sent_at || message.scheduled_send_time || message.timestamp}</Moment></Text>
                                                    </HStack>
                                                    <Text whiteSpace="pre-wrap">{message.text}</Text>
                                                </Stack>
                                            </Link>
                                        )
                                    }))
                                )}
                            </Stack>
                        </Stack>
                    </TabPanel> */}

                </TabPanels>
            </Tabs>
        </Stack >


    </Stack >
}



const ContactsTable = ({ data, stage_names, onContactClick, sortBy, onSortChange }: { data: ContactStats[], stage_names: string[], onContactClick?: (contactId: string) => any, sortBy: string, onSortChange: (col: string) => any }) => {
    function renderStep(stepStats: ContactStatsStep) {
        if (stepStats.error) {
            return <IconAlertTriangle size="20px" color="crimson" />
        }
        else if (stepStats.archived_reason) {
            return <Tooltip label={"Archived reason: " + stepStats.archived_reason}><IconCircleDashedX color="lightgray" /></Tooltip>
        }
        else if (stepStats.sent_at) {
            const formattedDate = new Intl.DateTimeFormat(undefined, { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(stepStats.sent_at + "+00:00"));
            return <Tooltip label={<Text>Sent at: {formattedDate} (<Moment fromNow tz={Intl.DateTimeFormat().resolvedOptions().timeZone}>{stepStats.sent_at}</Moment>)</Text>}><IconMailCheck size="20px" color="limegreen" /></Tooltip>
        }
        else if (stepStats.scheduled_send_time) {
            const formattedDate = new Intl.DateTimeFormat(undefined, { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(stepStats.scheduled_send_time + "+00:00"));
            return <Tooltip label={<Text>Scheduled: {formattedDate} (<Moment fromNow tz={Intl.DateTimeFormat().resolvedOptions().timeZone}>{stepStats.scheduled_send_time}</Moment>)</Text>}><IconCalendarTime size="20px" color="gray" /></Tooltip>
        }
    }
    const currentSort = sortBy?.startsWith("-") ? sortBy.substring(1) : sortBy;
    const direction = sortBy ? (sortBy.startsWith("-") ? "asc" : "desc") : undefined


    const ThSortable = ({ colName, children, ...rest }: { colName: string, [key: string]: any }) => {

        return <Th
            whiteSpace="nowrap"
            _hover={{ backgroundColor: "#f0f0f0" }}
            css={`
                & svg {
                display: inline !important;
                }
                `}
            cursor="pointer"
            onClick={() => onSortChange(colName !== currentSort ? colName : (sortBy != ("-" + colName) ? ("-" + colName) : undefined))}>
            {children}
            {currentSort == colName ? (direction == "asc" ? <IconChevronUp size="14px" /> : <IconChevronDown display="inline" size="14px" />) : (
                <IconSelector display="inline" size="18px" color="lightgray" />
            )}
        </Th>
    }

    return (
        <TableContainer overflowX="auto" overflowY="auto">
            <Table variant='simple' size="sm" >

                <Thead position="sticky" top="0px" background="white" >
                    <Tr verticalAlign="bottom" background="linear-gradient(to bottom, transparent,transparent, #f0f0f0)">
                        <ThSortable colName="contact_name">Contact Name</ThSortable>
                        {stage_names?.map((s, i) => (
                            <Th cursor="not-allowed">
                                <Stack spacing={0} overflow="hidden" maxW="120px">
                                    <Text>
                                        Step #{i + 1}
                                    </Text>
                                    <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" fontSize="2xs">{s}</Text>
                                </Stack>
                            </Th>
                        ))}
                        <ThSortable colName="accepted_invite" textAlign="center">Accepted<br />Invite</ThSortable>
                        <ThSortable colName="replied">Replied</ThSortable>
                        <ThSortable colName="account">Outreach<br />account</ThSortable>
                        <ThSortable colName="last_timestamp">Last activity</ThSortable>
                        <ThSortable colName="next_scheduled">Next Scheduled</ThSortable>
                    </Tr>

                </Thead>
                <Tbody fontWeight={500}>
                    {data?.map((contactStats, index) => (
                        <Tr key={index} verticalAlign="center"
                            _hover={{ backgroundColor: "#fafafa", cursor: "pointer" }}
                            onClick={() => onContactClick(contactStats.contact_id)}
                            css={`
                            &:hover .primary {
                                text-decoration: underline;
                            }
                            
                        `}>
                            <Td fontWeight={600} className="primary">{contactStats.contact_name}</Td>
                            {stage_names.map((s, i) => (
                                <Td justifyItems="center">{contactStats.steps && contactStats.steps[i] && renderStep(contactStats.steps[i])}</Td>
                            ))}
                            <Td justifyItems="center">{contactStats.accepted_invite ? <IconCheckbox size="20px" color="limegreen" /> : ""}</Td>
                            <Td >
                                <HStack>
                                    <Text>{contactStats.replied ? <IconCheckbox size="20px" color="limegreen" /> : ""}</Text>

                                    {contactStats.contact_status && <Tag>{contactStats.contact_status}</Tag>}
                                </HStack>
                            </Td>

                            <Td >{contactStats.account}</Td>
                            <Td>{contactStats.last_timestamp ? <Moment fromNow>{contactStats.last_timestamp}</Moment> : "N/A"}</Td>
                            <Td>{contactStats.next_scheduled ? <Moment fromNow>{contactStats.next_scheduled}</Moment> : "N/A"}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer >
    );
}



const AccountsTable = ({ data }: { data: AccountsStats[] }) => {


    return (
        <TableContainer overflowX="auto" overflowY="auto">
            <Table variant='simple' size="sm">
                <Thead position="sticky" top="0px" background="white">
                    <Tr verticalAlign="bottom" background="linear-gradient(to bottom, transparent,transparent, #f0f0f0)">
                        <Th >Account</Th>
                        <Th >Count</Th>
                        <Th >Errors</Th>
                        <Th >Replies</Th>
                        <Th >Statuses</Th>
                    </Tr>
                </Thead>
                <Tbody fontWeight={500}>
                    {data?.map((accountStats, index) => (
                        <Tr key={index} verticalAlign="center"


                        >
                            <Td fontWeight={600} className="primary">{accountStats.account}</Td>
                            <Td>{accountStats.count}</Td>
                            <Td>{accountStats.errors || 0}</Td>
                            <Td>{accountStats.replies || 0}</Td>
                            <Td>
                                {accountStats.by_statuses && Object.entries(accountStats.by_statuses).map(([status, count]) => (
                                    <Tag><Text fontWeight={500} fontSize="xs">{status}: </Text><Text fontWeight={900}>{count}</Text></Tag>

                                ))}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

const ContactDrawer = ({ contactId, campaignId, onClose }) => {

    //const contactMessagesFetch = useApiFetch<any[]>(`/inbox/contacts/${contactId}/activities`);

    return (
        <Drawer
            isOpen={true}
            placement='right'
            onClose={onClose}
            size="md"
        >
            <DrawerOverlay />
            <DrawerContent >
                <DrawerCloseButton />
                <DrawerHeader p="6px 10px">Contact Details</DrawerHeader>

                <DrawerBody p="8px">


                    <ContactInbox contactId={contactId} />
                    {/* <Text fontWeight="bold">Messages</Text>
                        <Stack overflow="auto" p="8px 10px">
                            {contactMessagesFetch.isLoading ? (
                                <Text>Loading messages...</Text>
                            ) : (
                                contactMessagesFetch.data?.map((message, index) => (
                                    <ActivityCard key={index} activity={message} />
                                ))
                            )}
                        </Stack> */}

                </DrawerBody>

                <DrawerFooter p="4px">
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}
export { CampaignDetail }