import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Heading, HStack, Stack, Switch, Text } from '@chakra-ui/react';
import { SearchFilter } from '../components/SearchFilter/searchFilter';
import { useApiFetch } from '../utils/useApiHook';
import { useFlow } from '../components/Flows/flowHook';
import { PageDefinition } from '../components/Flows/model';
import SearchResultsPage from '../components/Flows/PageTypes/searchResults';

// Custom hook for typewriter effect
const useTypewriter = (texts: string[], speed: number, isActive: boolean = true) => {
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);
    const [blink, setBlink] = useState(true);
    const [finalBlinks, setFinalBlinks] = useState(0);
    const [reverse, setReverse] = useState(false);

    // Function to calculate the overlap between current text and next text
    const calculateOverlap = (current: string, next: string) => {
        let i = 0;
        while (i < current.length && i < next.length && current[i] === next[i]) {
            i++;
        }
        return i;
    };

    const overlaps = useMemo(() => {
        return texts.map((text, i) => {
            const nextText = texts[(i + 1) % texts.length];
            return calculateOverlap(text, nextText);
        });
    }, [texts]);

    useEffect(() => {
        if (!isActive) return;

        const minFinalBlinkCount = subIndex / 5;
        if (index === texts.length) return;

        if (subIndex === texts[index].length + 1 && !reverse) {
            if (finalBlinks < minFinalBlinkCount) {
                return;
            } else {
                setFinalBlinks(0);
                setReverse(true);
            }
        }

        if ((subIndex === 0 || overlaps[index] == subIndex) && reverse) {
            setIndex((prev) => (prev + 1) % texts.length);
            setReverse(false);
            return;
        }

        const timeout = setTimeout(() => {
            setSubIndex((prev) => prev + (reverse ? -1 : 1));
        }, reverse ? 5 : speed || 50);

        return () => clearTimeout(timeout);
    }, [subIndex, index, reverse, texts, speed, isActive]);

    useEffect(() => {
        if (!isActive) return;

        const timeout = setTimeout(() => {
            setFinalBlinks((prev) => prev + 1);
            setBlink((prev) => !prev);
        }, 500);
        return () => clearTimeout(timeout);
    }, [blink, isActive]);

    return `${texts[index].substring(0, subIndex)}${blink ? "|" : " "}`;
};

interface MyCompanyInfo {
    id: string
    name: string
}


const CompanyRagPage = () => {
    const [isTypewriterActive, setIsTypewriterActive] = useState(true);
    const [currentSearch, _setCurrentSearch] = useState("");
    const getMyCompanyFetch = useApiFetch<MyCompanyInfo[]>("/meta/our_company_info");
    const setCurrentSearch = (search: string) => {
        _setCurrentSearch(search);
    };
    const [queryOptions, setQueryOptions] = useState({ preQualify: false, forCompany: "" });
    const {
        flowSession,
        currentPageState,
        updatePageState,
        isUpdating,
        updatingMode,
        nextPage,
        previousPage,
        withUpdateIndicator,
        handleErrors,
        setFlowSession,
        flowSessionService
    } = useFlow("search_companies");

    const [currentCompany, _setCurrentCompany] = useState<MyCompanyInfo>();

    useEffect(() => {
        if (getMyCompanyFetch.data?.length) {
            _setCurrentCompany(getMyCompanyFetch.data[0]);
            setQueryOptions({ preQualify: true, forCompany: getMyCompanyFetch.data[0].id });
        }
    }, [
        getMyCompanyFetch.data
    ]);

    const placeholderTexts = [
        "Describe your perfect customer",
        "Describe your perfect partner",
        "Describe your perfect investor",

        "i.e.: US SaaS company E-Commerce Startup that raised in recent months",
        "i.e.: E-commerce startups specializing in sustainable products",
        "i.e.: B2B marketplaces for the construction industry"
    ];
    const animatedPlaceholder = useTypewriter(placeholderTexts, 50, isTypewriterActive);

    const handleFocus = () => {
        setIsTypewriterActive(false);
    };

    const handleBlur = () => {
        if (currentSearch === "") {
            setIsTypewriterActive(true);
        }
    };

    function renderResultsPage(page: PageDefinition) {
        const params = {
            page: flowSession?.currentPage,
            onBack: previousPage,
            onNext: nextPage,
            currentPageIndex: flowSession?.currentPageIndex,
            totalPages: flowSession?.totalPages,
            currentPageState,
            setCurrentPageState: updatePageState,
            isUpdating,
            updatingMode,
            onFeedback: (feedback) => withUpdateIndicator("generate", flowSessionService.regenerate(flowSession.currentPage.id, feedback).then(setFlowSession).catch(handleErrors))
        };
        switch (page?.type) {
            case "search_results":
                return <SearchResultsPage {...params} />;

            default:
                return <Stack>
                    <h1>Unknown page type</h1>
                </Stack>
        }
    }

    function handleApplySearch() {
        updatePageState({ "query": currentSearch, "qualify": queryOptions?.preQualify, "my_company_id": queryOptions?.forCompany }).then(() => {
            console.log("Page state updated")
            nextPage()
        })
    }





    return (
        <Stack p={5} width="100%">
            <Text>{JSON.stringify(flowSession)}</Text>
            {!((flowSession?.currentPageIndex || 0) > 0) ? <Stack align="center" > /* Initial search UI*/
                <Heading as="h1" size="xl" mb={5}>
                    Search companies
                </Heading>
                <Stack width={["100%", "100%", "60vw", "700px"]}>
                    <SearchFilter
                        placeholder={isTypewriterActive ? animatedPlaceholder : "Enter your company description"}
                        search={currentSearch}
                        onChange={setCurrentSearch}

                        onApply={handleApplySearch}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </Stack>
                {currentSearch && <Stack fontWeight={500} fontSize="xs" align="center">

                    {!getMyCompanyFetch.isLoading && currentCompany ? (
                        <HStack>
                            <Switch size="sm" isChecked={queryOptions?.preQualify} onChange={(e) => {
                                setQueryOptions({ ...queryOptions, preQualify: e.target.checked })
                            }} />
                            <Text>pre-qualify them for my company</Text>
                            <Button size="xs">{currentCompany.name}</Button>
                        </HStack>
                    ) : (
                        <Stack p="10px" align="center">
                            <Text>We don't seem to have enough information about you to proceed</Text>
                            <Button size="xs" colorScheme="brand">finish your onboarding</Button>
                        </Stack>
                    )}
                </Stack>}
            </Stack> : (
                renderResultsPage(flowSession.currentPage)
            )}
        </Stack>
    );
};

export default CompanyRagPage;