import {
    Editable,
    EditableInput,
    EditablePreview,
    useEditableControls,
    IconButton,
    ButtonGroup,
    Flex,
    Input,
    EditableProps,
  HStack,
  } from '@chakra-ui/react'
import { IconEdit } from '@tabler/icons-react'
import { IconCheck, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'

export function EditableField({ label, value, onApply, required, size, onChange, ...rest }: {
  label?: string,
  value: string,
  required?: boolean
  size?: "sm" | "md" | "lg"
  onApply: (value: string) => void
  onChange?: (value: string) => void
  [key: string]: any
}) {
    /* Here's a custom control */
  const [tmpValue, setTmpValue] = useState<string>()
  useEffect(() => {
    setTmpValue(value || "")
  }, [value])

  function getPad(): string {
    switch (size) {
      case "sm": return "0px 4px"
      case "md": return "4px 6px"
      case "lg": return "6px 6px"
      default: return "4px 6px"
    }
  }

  return (

    <Editable flexGrow={1} value={tmpValue} maxH="2em" flexBasis="auto"

      size={size} {...rest}>
      <EditablePreview fontSize={size} maxW="100%"
        minH={25}
        _hover={{ border: "1px solid gray", backgroundColor: "white" }}
        noOfLines={1}
        border={(required && !tmpValue) ? "1px solid red" : "1px dotted lightgray"} color={tmpValue ? "#050505" : "#cacaca"} borderRadius={5}
        p={getPad()} width="100%" />

      <EditableInput fontSize={size} backgroundColor="white" width="100%" p={getPad()} onChange={(e) => {
        setTmpValue(e.target.value)
        onChange && onChange(e.target.value)
      }} onBlur={() => {
        onApply(tmpValue)

        }} />
      </Editable>

    )
  }