import React, { useState } from 'react';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { AutoUI, GeniouslyThemeProvider } from 'geniously-chat-ui';
import FlowPageWrapper from './pageWrapper';
import FlowPageProps from './pageProps';

interface SearchStepSection {
    title: string;
    details: string | Record<string, any> | "CanvasSelectableSection";
}

interface SearchResultsPage {
    query: string;
    steps_sections: SearchStepSection[];
    filters: Array<Record<string, any>>;
    data: Array<Record<string, any>>;
    stats?: Record<string, any> | null;
    total?: number | null;
}

const SearchResultsPage: React.FC<FlowPageProps> = (props: FlowPageProps) => {
    const [searchData, setSearchData] = useState<SearchResultsPage>(props.page.search_results as any || { query: '', steps_sections: [], filters: [], data: [] });

    const handleFilterChange = (filterKey: string, value: any) => {
        setSearchData((prevData) => ({
            ...prevData,
            filters: prevData.filters.map((filter) =>
                filter.key === filterKey ? { ...filter, value } : filter
            ),
        }));
    };

    return (
        <FlowPageWrapper pageProps={props}>
            <Stack p="10px">
                <GeniouslyThemeProvider>
                    <Heading as="h1">Search Results</Heading>
                    <AutoUI size="xl" value={searchData} schema={props.page.search_results?.data_schema}
                        showOnlySchemaFields
                        onValueChange={(val) => {
                            if (JSON.stringify(val) !== JSON.stringify(searchData)) {
                                setSearchData(val);
                            }
                        }} />
                    {/* Add more components to display search results, filters, stats, etc. */}
                </GeniouslyThemeProvider>
            </Stack>
        </FlowPageWrapper>
    );
};

export default SearchResultsPage;