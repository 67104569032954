import { ChatService } from 'geniously-chat-ui';
import { PageDefinition, FlowSession } from './model';
import { getApi } from '../../apiService';

interface FlowSessionServiceType {
    connect(restoreSessionId?:string):Promise<FlowSession>;
    update(currentPageId: string, pageData: PageDefinition): Promise<FlowSession>;
    regenerate(currentPageId: string, feedback: string): Promise<FlowSession>;
    confirmPage(currentPageId: string): Promise<FlowSession>;
    onPartialUpdate(callback: (pageData:PageDefinition) => void): () => void;
    onMessage(callback: (message: any, type?:"error"|"warning"|"info") => void): () => void;
    pages:PageDefinition[]
}

 

class MockFlowSessionService implements FlowSessionServiceType {
    private flowSession: FlowSession;
    private partialUpdateCallbacks: Set<(pageUpdate: PageDefinition) => void>;
    private onMessageCallbacks: Set<(message: any, type?:"error"|"warning"|"info") => void>;
    public pages:PageDefinition[]
    

   

    constructor() {
        this.flowSession =null;
        this.partialUpdateCallbacks = new Set();
        this.onMessageCallbacks=new Set()
        
        this.pages=[
            {
                id:"welcome",
                title: "Welcome",
                type: "form",
                ready: true,
                description: "Please fill in your company email.\n\n We'll use it to extract information about your company to train our AI",
                form: {
                    data: {},
                    data_schema: {
                        type: "object",
                        properties: {
                            email: {
                                type: "string",
                                title: "Company email",
                                format: "email",
                                example: "your.name@your-company.com",
                                pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
        
                            }
                        },
                        required: ["email"]
                    }
                },
                problems:[
                    {
                        "type":"warning",
                        "message":"Hey there"
                    },
                    {
                        "type":"error",
                        "message":"Pls use your company email",
                        "path":"email"
                    }
                ]
            },
            {
                id:"company_details",
                title: "Company Details",
                type: "form",
                ready: true,
                description: "Please provide some details about your company.",
                form: {
                    data: {},
                    data_schema: {
                        type: "object",
                        properties: {
                            companyName: {
                                type: "string",
                                title: "Company Name",
                                example: "Your Company Inc."
                            },
                            companySize: {
                                type: "number",
                                title: "Number of Employees",
                                example: 100
        
                            }
                        },
                        required: ["companyName", "companySize"]
                    }
                },
               
            },
            {
                id:"canvas",
                title: "Setup Complete",
                type: "canvas",
                description: "Your setup is complete. You can now start using our services. \n\nPlease take a moment to explore the features available to you. \n\nIf you have any questions, refer to our help section or contact support.",
                canvas: {
                    sections: [
                        "# Hi",
                        {
                            id:"google",
                            type: "selectable",
                            title: "Google",
                            description: "Don't be **evil**\nor be... whatever",
                            image: "https://www.google.com/favicon.ico"
                        },
                    {
                        id: "apple",
                        type: "selectable",
                        title: "Apple",
                        description: "Think different.",
                        image: "https://www.apple.com/favicon.ico"
                    },
                    {
                        id: "microsoft",
                        type: "selectable",
                        title: "Microsoft",
                        description: "Empowering us all.",
                        image: "https://www.microsoft.com/favicon.ico"
                    },
                    {
                        id: "amazon",
                        type: "selectable",
                        title: "Amazon",
                        description: "Work hard. Have fun. Make history.",
                        image: "https://www.amazon.com/favicon.ico"
                    },
                    {
                        id: "facebook",
                        type: "selectable",
                        title: "Facebook",
                        description: "It's quick and easy.",
                        image: "https://www.facebook.com/favicon.ico"
                    },
                    {
                        id: "twitter",
                        type: "selectable",
                        title: "Twitter",
                        description: "What's happening?",
                        image: "https://www.twitter.com/favicon.ico"
                    },
                    {
                        id: "netflix",
                        type: "selectable",
                        title: "Netflix",
                        description: "See what's next.",
                        image: "https://www.netflix.com/favicon.ico"
                    },
                    {
                        id: "tesla",
                        type: "selectable",
                        title: "Tesla",
                        description: "Electric cars, solar & clean energy.",
                        image: "https://www.tesla.com/favicon.ico"
                    },
                    {
                        id: "spacex",
                        type: "selectable",
                        title: "SpaceX",
                        description: "Space exploration technologies.",
                        image: "https://www.spacex.com/favicon.ico"
                    }
                    ]
                }
            }
        ];
    }

    async connect(estoreSessionId?:string): Promise<FlowSession> {
        // Mock implementation of startNewSession
        this.flowSession = {
            currentPage: this.pages[0],
            currentPageIndex: 0,
            totalPages: this.pages.length,
            flowSessionId: `session${Math.floor(Math.random() * 1000)}`
        };
        return this.flowSession;
    }

    _updatePageIndex(currentPageId:string) {
        let pageIndex = this.pages.findIndex(p=>p.id==currentPageId)
        if (this.flowSession?.currentPageIndex!=pageIndex){
            this.flowSession = { ...this.flowSession, currentPageIndex:pageIndex+1, currentPage:this.pages[pageIndex+1]};
        }
        
    }
    async update(currentPageId: string, pageData: any): Promise<FlowSession> {
        // Mock implementation of update
        this._updatePageIndex(currentPageId)
        if (!pageData) return;
        if (this.flowSession.currentPage.form){
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 seconds
            this.flowSession.currentPage = {
                ...this.flowSession.currentPage,
                form: {
                    ...this.flowSession.currentPage.form,
                    data: pageData.form?.data || this.flowSession.currentPage.form?.data
                }
            };

            
            return this.flowSession;
        }
        else if (this.flowSession.currentPage.canvas){
            this.flowSession.currentPage = {
                ...this.flowSession.currentPage,
                canvas: {
                    ...this.flowSession.currentPage.canvas,
                    selectedSections: pageData.selectedSections
                }
            };
        }
        return this.flowSession
    }

    async regenerate(currentPageId: string, feedback: string): Promise<FlowSession> {
        // Mock implementation of regenerate
        this._updatePageIndex(currentPageId)
        await this.simulatePartialUpdate(20)
        return this.flowSession;
    }

    async confirmPage(currentPageId: string): Promise<FlowSession> {
        // Mock implementation of confirmPage
        let pageIndex = this.pages.findIndex(p=>p.id===currentPageId)
        if (pageIndex==this.pages?.length-1) return;
        this.flowSession = { ...this.flowSession, currentPageIndex:pageIndex+1, currentPage:this.pages[pageIndex+1]};
        
        await this.simulatePartialUpdate()
        return this.flowSession;
    }

    onPartialUpdate(callback: (pageUpdate: PageDefinition) => void): () => void {
        this.partialUpdateCallbacks.add(callback);

        // Return a function to unregister the callback
        return () => {
            this.partialUpdateCallbacks.delete(callback);
        };
    }

    onMessage(callback: (message: any, type?:"error"|"warning"|"info") => void): () => void {
        this.partialUpdateCallbacks.add(callback);

        // Return a function to unregister the callback
        return () => {
            this.partialUpdateCallbacks.delete(callback);
        };
    }

    // Simulate partial updates
    async simulatePartialUpdate(steps=3) {
            const originalInstructions = this.flowSession.currentPage.description;

            for (let i = 1; i <= steps; i++) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                this.flowSession.currentPage.description += `\nline ${i}`;
                this.partialUpdateCallbacks.forEach(callback => callback(this.flowSession.currentPage));
            }

            this.flowSession.currentPage.description = originalInstructions;
            
    }
}


class FlowSessionService implements FlowSessionServiceType {
    public flowSessionId: string;
    public pages: PageDefinition[];
    public ws_url: string;
    public flowName: string;
    private socket: WebSocket;
    public options:{timeout:number}
    private partialUpdateCallbacks: Set<(pageUpdate: PageDefinition) => void>;
    private onMessageCallbacks: Set<(message: any, type?:"error"|"warning"|"info") => void>;
    private awaitingResponseTimer:any
    private awaitingResponsePromiseResolver:[(session:FlowSession)=>void,(error:Error)=>void]
    private reconnectAttempts: number = 0;
    private maxReconnectAttempts: number = 5;
    private reconnectTimeout: number = 1000; 


    constructor(flowName: string, options?:{timeout:number}) {
        this.flowName = flowName;
        this.partialUpdateCallbacks=new Set()
        this.onMessageCallbacks=new Set()
        
        this.ws_url = getApi().baseUrl.replace("http", "ws") + "/flows/" + flowName;
        const defaultOptions={
            timeout:1000*60
        }
        this.options={...defaultOptions,...(options||{})}

    }

    async connect(restoreSessionId?: string): Promise<FlowSession> {
        this.flowSessionId = restoreSessionId;
        const url = new URL(this.ws_url);
        let authHeaders=await getApi().authorize()
        if (authHeaders?.Authorization){
            url.searchParams.append('auth', authHeaders?.Authorization);
        }
        if (restoreSessionId) {
            url.searchParams.append('session_id', restoreSessionId);
        }
        this.socket = new WebSocket(url.toString());

        return new Promise((resolve, reject) => {
            this.socket.onopen = () => {
                
                this.socket.onmessage = (event) => {
                    const message = JSON.parse(event.data) as {type:string, session:FlowSession, previous_pages?:PageDefinition[]};
                    if (message.type === "start") {
                        this.pages = [...(message.previous_pages || []),message.session.currentPage];
                        this.flowSessionId = message.session.flowSessionId;
                        resolve(message.session);
                        this.socket.onmessage=(message)=>this.handleIncomingMessage(message)
                        this.socket.onerror = (error) => {
                            this.handleMessageReceived("Disconnected ... reconnecting", "error");
                            this.reconnect();
                        };
                    }
                };
    
                this.socket.onerror = (error) => {
                    reject(error);
                };
                this.socket.onclose = (event) => {
                    this.handleMessageReceived("Connection lost... reconnecting", "error");
                    this.reconnect();
                        
                }
            };

        });
    }

    private async reconnect(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.reconnectAttempts < this.maxReconnectAttempts) {
                this.reconnectAttempts++;
                const backoffTime = this.reconnectTimeout * Math.pow(2, this.reconnectAttempts - 1);
                setTimeout(() => {
                    this.connect(this.flowSessionId).then(() => {
                        this.handleMessageReceived(undefined);
                        resolve();
                    }).catch(() => {
                        this.reconnect().then(resolve).catch(reject);
                    });
                }, backoffTime);
            } else {
                this.handleMessageReceived("Max reconnection attempts reached. Please check your connection.", "error");
                reject(new Error("Max reconnection attempts reached"));
            }
        });
    }


     // Central message handler
    private handleIncomingMessage(event: MessageEvent) {
        const message = JSON.parse(event.data);
        switch (message.type) {
            case "refresh_session":
                this.handleRefreshSessionMessage(message);
                break;
            case "preview":
                this.handlePreviewMessage(message);
                break;
            // Add more cases as needed
            default:
                console.warn(`Unhandled message type: ${message.type}`);
        }
    }

    private handleRefreshSessionMessage(message: { type: string, session: FlowSession }) {
        // Clear the timeout and resolve the promise
        clearTimeout(this.awaitingResponseTimer);
        if (this.awaitingResponsePromiseResolver){
            this.awaitingResponsePromiseResolver[0](message.session);
        }
    }

    private handlePreviewMessage(message: { type: string, page: PageDefinition }) {
        clearTimeout(this.awaitingResponseTimer);
        this.awaitingResponseTimer = setTimeout(() => {
            this.awaitingResponsePromiseResolver[1](new Error("Timeout waiting for refresh_session"));
        }, this.options.timeout);
        this.partialUpdateCallbacks.forEach(callback => callback(message.page));
    }
    

    async update(currentPageId: string, pageData: PageDefinition): Promise<FlowSession> {
        return this.sendAction("update_state", currentPageId, pageData);
    }

    async regenerate(currentPageId: string, feedback: string): Promise<FlowSession> {
        return this.sendAction("feedback", currentPageId, feedback );
    }

    async confirmPage(currentPageId: string): Promise<FlowSession> {
        return this.sendAction("next", currentPageId);
    }

    onPartialUpdate(callback: (pageUpdate: PageDefinition) => void): () => void {
        this.partialUpdateCallbacks.add(callback);

        // Return a function to unregister the callback
        return () => {
            this.partialUpdateCallbacks.delete(callback);
        };
    }

    onMessage(callback: (message: any, type?:"error"|"warning"|"info") => void): () => void {
        this.onMessageCallbacks.add(callback);

        // Return a function to unregister the callback
        return () => {
            this.onMessageCallbacks.delete(callback);
        };
    }

    private async handleMessageReceived(message: any, type?:"error"|"warning"|"info") {
        this.onMessageCallbacks.forEach(callback => callback(message,type));
    }

    private async sendAction(type: string, currentPageId: string, data?: any): Promise<FlowSession> {
        if (!(this.socket && this.socket.readyState === WebSocket.OPEN)) {
            this.handleMessageReceived("Not connected to server ... reconnecting", "error");
            await this.reconnect()
        }
        return new Promise((resolve, reject) => {
            const action = {
                type,
                current_step_id: currentPageId,
                data:data
            };

            this.socket.send(JSON.stringify(action));

            this.awaitingResponseTimer = setTimeout(() => {
                reject(new Error("Timeout waiting for refresh_session"));
            }, this.options.timeout);

            this.awaitingResponsePromiseResolver = [resolve,reject];
            
        });
    }
}



export { FlowSessionServiceType ,FlowSessionService, MockFlowSessionService };