

import { useEffect, useState } from "react";



import { useNavigate, useSearchParams } from "react-router-dom";
import { getApi } from "../apiService";


import { Flex } from "@chakra-ui/react";

import { Task } from "geniously-chat-ui";
import { WorkDebugDetail } from "../components/WorkflowDebuging/taskDebugDetail";
import { WorkflowList } from "../components/WorkflowDebuging/workflowList";


export  default function WorkflowDebugPage(){
    
    useEffect(() => {
        document.title = "Admin - Easypie";
    }, []);

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedTask, setSelectedTask] = useState<Task>()

    useEffect(() => {
        if (searchParams) {
            const selected = searchParams.get("selected")
            if (selectedTask && selectedTask?.id !== selected) {
                setSearchParams({ ...Object.fromEntries(searchParams.entries()),selected: selectedTask?.id })
            }
            else if (!selectedTask && selected) {
                getApi().getTask(selected).then((workflow)=>{
                    setSelectedTask(workflow)
                })
            }
        }
    }, [searchParams])
    
    useEffect(() => {
        if (searchParams && selectedTask && selectedTask.id!==searchParams.get("selected")) {
            setSearchParams({ ...Object.fromEntries(searchParams.entries()),selected: selectedTask?.id })
        }
    }, [selectedTask])

    


    return ( <Flex direction="row" align="stretch" flexGrow={1} justify="stretch"  >
        <WorkflowList onTaskSelected={(workflow)=>{
            setSelectedTask(workflow)
        }} 
        selectedTask={selectedTask}
        />
      <WorkDebugDetail task={selectedTask} />
    </Flex>
    
    )
}
