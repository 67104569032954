import { useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ReactQuill from 'react-quill';

import { ButtonGroup, Link, Tag, Tooltip, useToast } from "@chakra-ui/react";
import {
  Badge,
  Button,
  HStack,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";

import {
  IconBolt,
  IconBook,
  IconChevronDown,
  IconChevronUp,
  IconPlugConnected,
  IconSettings,
  IconSquare,
  IconSquareCheck,
  IconWebhook,
} from "@tabler/icons-react";
import { IconApi } from "@tabler/icons-react";

import { IconArrowsExchange2 } from "@tabler/icons-react";
import { IconSettingsCode } from "@tabler/icons-react";
import { getApi } from "../../apiService";
import { AppProject, Integration } from "../../models/dataModel";

import { EditModal } from "../ModalDialogs/editModal";
import { IconUsersPlus } from "@tabler/icons-react";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import IntegrationIcon from "./integrationIcon";
import { AutosizeTextArea, GeniouslyThemeProvider } from "geniously-chat-ui"

import { useApiEndpoint } from "../../utils/useApiHook";
import 'react-quill/dist/quill.snow.css';
import SliderWithMarks from "../SliderWithMarks/sliderWithMark";

const EmailIntegrationDetail = ({
  integration: _integration,

  icon,
  moreInfo,
  onConnect,
  onChange,
}: {
  integration: Integration;

  icon?: any;
  moreInfo?: any;

  onConnect?: () => Promise<any> | void;
  onChange?: (val: Integration) => void;
}) => {
  const [dsAdded, setDsAdded] = useState(false);
  const [actionsAdded, setActionsAdded] = useState(false);

  const [integration, setIntegration] = useState<Integration>();
  const [modal, setModal] = useState<any>(null);
  useEffect(() => setIntegration(_integration), [_integration]);
  function getIntegration() {
    return getApi()
      .getIntegration(_integration.connector)
      .then((res) => {
        setIntegration(res);
        return res;
      });
  }


  const [accountPatch, setAccountPatch] = useState<any>()
  const [connecting, setConnecting] = useState(false)
  const [accountSettingsEdited, setAccountSettingsEdited] = useState<any>()
  const patchAccountEndpoint = useApiEndpoint<any>("PATCH", "/integrations/{connector}/accounts/{account}", false)
  return !integration ? (
    <></>
  ) : (
    <Stack>
      {modal}
      <Stack direction="row">
        {<IntegrationIcon type={integration.connector} />}
        <Heading size="md">{integration.name}</Heading>
      </Stack>
      {moreInfo ?? (
        <>
          {typeof moreInfo == "string" ? (
            <Text>{moreInfo}</Text>
          ) : (
            <Stack>{moreInfo} </Stack>
          )}
        </>
      )}
      {integration.datasources?.length > 0 && (
        <Stack align="start">
          <Text fontSize="xs" fontWeight={700}>
            Available datasources
          </Text>
          <HStack align="start">
            {integration.datasources.map((ds) => (
              <HStack
                key={ds.datasource_id}
                direction="row"
                border="1px solid gray"
                rounded="3px"
                p="0px 5px"
              >
                <IconBook size="15px" />
                <Text fontSize="xs" fontWeight={500}>
                  {ds.name}
                </Text>
              </HStack>
            ))}
          </HStack>
          {/* {(app &&onAppChange && !dsAdded) &&  <Button p="0px 45px" colorScheme="green" 
                  leftIcon={<IconSquareRoundedPlus/>}  onClick={addDataSources}>Add all datasources</Button>} */}
        </Stack>
      )}
      {integration.actions?.length > 0 && (
        <Stack align="start">
          <Text fontSize="xs" fontWeight={700}>
            Available actions
          </Text>
          <HStack align="start">
            {integration.actions.map((action) => (
              <HStack
                direction="row"
                border="1px solid gray"
                rounded="3px"
                p="0px 5px"
              >
                <IconBolt size="15px" />
                <Text fontSize="xs" fontWeight={500}>
                  {action.action_name}
                </Text>
              </HStack>
            ))}
          </HStack>
          {/* {(app &&onAppChange && !actionsAdded) &&  <Button   p="0px 45px" colorScheme="green"  
                  leftIcon={<IconSquareRoundedPlus/>} onClick={addCommandActions}>Add all actions</Button>} */}
        </Stack>
      )}
      {integration?.active && (
        <Text>
          Integration is <Tag>active</Tag>
        </Text>
      )}
      <Stack m="10px 0px">
        {integration.support_multiple_accounts ? (
          <Stack align="stretch" m="10px 0px">
            {integration.accounts?.map((account) => (
              <Stack border="1px solid lightgray" rounded="3px"
                p="5px">
                <HStack


                  justify="space-between"
                >
                  <Text fontSize="xs" fontWeight={700}>
                    Account: {account.name}
                  </Text>
                  <HStack alignSelf="end">
                    <Button
                      size="xs"
                      colorScheme="gray"
                      variant="outline"
                      onClick={() => onConnect()}
                    >
                      Reconnect
                    </Button>
                    {account.primary ? (
                      <Badge colorScheme="gray">Primary</Badge>
                    ) : (
                      <>
                        <Button
                          size="xs"
                          colorScheme="gray"
                          variant="outline"
                          onClick={() =>
                            getApi()
                              .patchIntegrationAccount(
                                integration.connector,
                                account.name,
                                { primary: true }
                              )
                              .then(() => getIntegration())
                          }
                        >
                          Make primary
                        </Button>
                        <Button
                          size="xs"
                          colorScheme="red"
                          variant="outline"
                          onClick={() =>
                            getApi()
                              .patchIntegrationAccount(
                                integration.connector,
                                account.name,
                                { active: false }
                              )
                              .then(() => getIntegration())
                          }
                        >
                          Remove
                        </Button>
                      </>
                    )}
                    {accountPatch && accountSettingsEdited == account.name ? <HStack> <Button size="xs" colorScheme="brand" isLoading={patchAccountEndpoint.isRunning}
                      onClick={() => {
                        patchAccountEndpoint.execute({ "connector": integration.connector, "account": account.name },
                          accountPatch
                        )
                          .then(() => {
                            getIntegration()
                            setAccountPatch(undefined)
                          })
                      }}
                    >Save</Button>
                      <Button size="xs"
                        onClick={() => {
                          setAccountPatch(undefined)
                        }}
                      >Cancel</Button>

                    </HStack> :
                      <Button size="xs"
                        isDisabled={!!accountPatch}
                        leftIcon={<IconSettings />}
                        rightIcon={accountSettingsEdited ? <IconChevronUp /> : <IconChevronDown />}
                        variant="outline"
                        colorScheme="brand" onClick={() => setAccountSettingsEdited(accountSettingsEdited ? undefined : account.name)}>Settings</Button>}
                    <Badge
                      cursor="pointer"
                      onClick={() => {
                        setModal(<ConfirmModal
                          onCancel={() => setModal(null)}
                          onOk={() => {
                            patchAccountEndpoint.execute({ "connector": integration.connector, "account": account.name },
                              { active: !account.active }
                            )
                              .then(() => getIntegration())
                            setModal(null)
                          }}

                          caption={account.active ? "Deactivate account" : "Activate account"} question={`Do you want to ${account.active ? "deactivate " : "activate account"} ${account.name} account? `} />)
                      }} colorScheme={account.active ? "green" : "gray"} ><HStack>{account.active ? <IconSquareCheck color="gray" size="15px" /> : <IconSquare size="15px" />}<Text> {account.active ? "Active" : "Disabled"}</Text></HStack></Badge>
                  </HStack>
                </HStack>
                {accountSettingsEdited == account.name && <AccountSettings account={!accountPatch ? account : { ...account, ...accountPatch }} onSettingsChange={(new_settings) => {
                  setAccountPatch({ ...(accountPatch || {}), settings: new_settings })
                }} />}
              </Stack>
            ))}
              {onConnect && <Button
              colorScheme="brand"
              size="xs"
              alignSelf="start"
                isLoading={connecting}
              leftIcon={<IconUsersPlus size="18px" />}
              onClick={() => {
                setConnecting(true)
                let res = onConnect();
                if (res) {
                  res.then(() => {
                    getIntegration()
                  }).finally(() => setConnecting(false));
                }
              }}
            >
              Connect another account
              </Button>}
          </Stack>
        ) : (
          <Button
            colorScheme="brand"
            mt="10px"
            size="xs"
            variant="outline"
                isLoading={connecting}
            alignSelf="start"
            leftIcon={<IconPlugConnected />}
            onClick={() => {
              setConnecting(true)
              let res = onConnect();
              if (res) {
                res.then(() => {
                  getIntegration()
                }).finally(() => setConnecting(false));
              }
            }}
          >
            {!integration?.active
              ? "Connect integration"
              : "Reconnect integration"}
          </Button>
        )}
      </Stack>

      {/* {integration.settings_schema && (
        <Stack>
          <AutoUI
            schema={integration.settings_schema}
            value={integration.settings}
            onValueChange={(val) => {
              let newIntegration = { ...integration, settings: val };
              setIntegration(newIntegration);

              onChange(newIntegration);
            }}
          />
        </Stack>
      )} */}
    </Stack>
  );
};

const AccountSettings = ({ account, onSettingsChange }: { account: any, onSettingsChange: (new_settings: any) => void }) => {
  const [editorType, setEditorType] = useState<"visual" | "html">("html")
  return <Stack>
    <Stack p="0px 6px" flexShrink={0}>
      <HStack justify="space-between" flexShrink={0}>
        <Text fontSize="xs" fontWeight={600}>Signature</Text>
        <ButtonGroup isAttached >
          <Button size="xs" colorScheme="brand" variant={editorType == "visual" ? "solid" : "outline"} onClick={() => setEditorType("visual")}>Visual editor</Button>
          <Button size="xs" colorScheme="brand" variant={editorType == "html" ? "solid" : "outline"} onClick={() => setEditorType("html")}>HTML</Button>
        </ButtonGroup>
      </HStack>
      {editorType == "visual" ?

        (<Stack flexShrink={0} pb="45px">
          <ReactQuill theme="snow" value={account.settings?.signature || ""} onChange={(val) => {
            onSettingsChange({ ...(account.settings || {}), signature: val })
          }} />
        </Stack>
        ) :
        <GeniouslyThemeProvider>
          <AutosizeTextArea value={account.settings?.signature || ""} onApply={(e) => onSettingsChange({ ...(account.settings || {}), signature: e })} />
        </GeniouslyThemeProvider>
      }

      <SliderWithMarks value={account.settings?.limits?.daily_messages_requests || 100}
        marks={[0, 2, 5, 10, 15, 20, 25, 30, 50]}
        limits={[
          { limit: 0, color: "green", info: "For new email accounts" },
          { limit: 2, color: "green", info: "Safe zone" },
          { limit: 2, color: "green", info: "Normal" },
          { limit: 10, color: "green", info: "For active/warmed up email accounts" },

          { limit: 15, color: "orange", info: "Risky" },

          { limit: 30, color: "red", info: "Danger" },
          { limit: 50, color: "red", info: "☠️ Danger" }
        ]}
        onChange={(val) => {
          onSettingsChange({ ...(account.settings || {}), limits: { ...(account.settings?.limits || {}), daily_messages_requests: val } })

        }} />

    </Stack>
  </Stack>
}

export default EmailIntegrationDetail;