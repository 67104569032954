import { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, CardBody, CardFooter, CardHeader, ChakraProvider, Divider, Flex, HStack, Heading, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Portal, SimpleGrid, Spinner, Stack, Switch, Tag, TagLabel, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";






import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { IconHeart, IconSettings2, IconSparkles, IconTrash, IconWindowMaximize, IconWorldSearch, IconX } from "@tabler/icons-react";
import { SearchFilter } from "../SearchFilter/searchFilter";
import { IconPlayerPlay } from "@tabler/icons-react";
import { IconTablePlus } from "@tabler/icons-react";
import { LuImport } from "react-icons/lu";
import { ListOp, ListOpTypeInfo } from "./model";
import { IconStar } from "@tabler/icons-react";
import { useApiEndpoint, useApiFetch } from "../../utils/useApiHook";

function humanizeText(text: string) {
  if (text.length < 5) return text.toUpperCase()
  return text
    .replace(/_/g, ' ')
    .replace(/^\w/, (c) => c.toUpperCase());
}
interface SavedOp {
  id: string,
  name: string,
  op_type: string,
  notes?: string
  list_op: ListOp
}

const ListOpsSelectionDialog = ({ opTypes, defaultCategory, onOk, onCancel }: {
  opTypes: ListOpTypeInfo[],
  defaultCategory?: string,
  onOk: (opType: ListOpTypeInfo, op?: ListOp) => void,
  onCancel: () => void
}) => {

  const [searchQuery, setSearchQuery] = useState<string>("")
  const categories = useMemo(() => ["Popular", ...Array.from(new Set(opTypes.flatMap(op => op.tags || []))), "other"], [opTypes])
  const [selectedCategory, setSelectedCategory] = useState<string>(defaultCategory || "Popular")

  const deleteSavedOpEndpoint = useApiEndpoint("DELETE", "/saved-list-ops/{id}")
  const allSavedOpsFetch = useApiFetch<SavedOp[]>("/saved-list-ops", { static: true })

  function deleteSavedOp(id: string) {
    return deleteSavedOpEndpoint.invoke({ pathArgs: { id } }).then(() => {
      allSavedOpsFetch.mutate()
    }
    )
  }

  const ops = useMemo(() => {
    let main = opTypes.filter(op => op.category != "filter")
    allSavedOpsFetch.data?.forEach(savedOp => {
      main.push({ list_op: savedOp.list_op, name: savedOp.name, description: savedOp.notes, op_type: savedOp.list_op.op_type, tags: ["Saved"], onDelete: () => deleteSavedOp(savedOp.id) })
    })
    return main
  }, [opTypes, allSavedOpsFetch.data])
  const popularOpsNames = [
    "Add generated column", "Add research column", "Add classification column", "Add formula column", "Find persona"
  ]

  const popularOps = useMemo(() => popularOpsNames.map(opName => opTypes.find(o => o.name == opName)).filter(a => a), [])




  function filterOps(op: { name: string, description?: string, tags?: string[] }, filterByCategory: boolean = true) {

    if (searchQuery?.trim().length && !op.name.toLowerCase().includes(searchQuery.toLowerCase()) && !((op.description || "") + " " + op.tags?.join(",")).toLowerCase().includes(searchQuery.toLowerCase()))
      return false
    if (filterByCategory && selectedCategory && (!op.tags?.includes(selectedCategory) && !(!op.tags?.length && selectedCategory == "other")))
      if (selectedCategory == "Popular" && popularOpsNames.includes(op.name))
        return true
      else
        return false

    return true
  }
  const filteredByCategory = useMemo(() => ops.filter(op => filterOps(op, true)), [selectedCategory, searchQuery, ops])
  const filteredByQuery = useMemo(() => ops.filter(op => filterOps(op, false)), [selectedCategory, searchQuery, ops])

  const categoriesWithCounts = useMemo(() => {

    return categories.filter(c => c !== "hidden").map((c) => ({
      category: c,
      count: c == "Popular" ? popularOpsNames.length : ops.filter(op => (op.tags?.includes(c) || (!op.tags?.length && c == "other")) && !op.tags?.includes("hidden")).length
    }))
  }, [ops])


  return (
    <Modal isOpen={true} onClose={() => onCancel()} size="6xl">
      <ModalOverlay />
      <ModalContent>

        <ModalCloseButton />
        <ModalBody>
          <Stack >
            <HStack justify="space-between">
              <Text mr="-100px" fontWeight={900}>Available actions</Text>
              <HStack justify="stretch" justifySelf="center" alignSelf="center" maxW="400px" flexGrow={1} width="100%" p="20px">
                <SearchFilter autoFocus flexGrow={1} autoApply placeholder="Search in available actions" autoApplyDelay={300} search={searchQuery} onApply={setSearchQuery} />
              </HStack>
              <Box ></Box>
            </HStack>
            {/* <Text>

              
            </Text> */}
            <HStack align="stretch" spacing={0} >
              <Stack className="ops-side-options" width="200px" height="100% important!" flexGrow={1} justify="space-between" >
                <Stack >
                  {categoriesWithCounts.map((c) => (
                    <SidebarOption
                      key={c.category}
                      icon={c.category == "Popular" ? (<IconStar size="15px" />) : undefined}
                      category={c.category}
                      count={c.count}
                      isSelected={c.category === selectedCategory}
                      onSelect={() => setSelectedCategory(c.category)}
                    />
                  ))}
                </Stack>
                <Stack pb="20px">
                  <SidebarOption
                    icon={<IconHeart size="15px" />}
                    category={"Saved"}
                    count={allSavedOpsFetch.data?.length || 0}
                    isSelected={"Saved" === selectedCategory}
                    onSelect={() => setSelectedCategory("Saved")}
                  />
                </Stack>


              </Stack>
              <Stack className="ops-mainPanel" backgroundColor="gray.100" width="700px" borderRadius={10} flexGrow={1} p="10px" height="65vh" overflow="auto" align="start">
                {/* <Text>{JSON.stringify(selectedCategory)}</Text> */}


                <Wrap width="100%">
                  {((selectedCategory != "Popular" || searchQuery) ? (filteredByCategory) : (popularOps))?.map((op, i) => (<OpCard key={i} opInfo={op} onClick={() => onOk(op, (op as any).list_op)} />))
                  }
                </Wrap>
                {(searchQuery && filteredByQuery?.length && (
                  <Stack alignSelf="start" width="100%">
                    <Stack align="center" spacing={10} m="10px">
                      {!filteredByCategory?.length && <HStack p="10px">

                        <Text>No results found in </Text><Tag colorScheme="brand" variant="outline"> {humanizeText(selectedCategory)}</Tag><Text> category...</Text>
                      </HStack>}
                      <Text alignSelf="start" fontWeight={600}>Search results for other categories:</Text>
                    </Stack>
                    <Wrap width="100%">

                      {filteredByQuery.filter(op => !filteredByCategory?.find(o => o.name == op.name))?.map((op, i) => (<OpCard key={i} opInfo={op} onClick={() => onOk(op, (op as any).list_op)} />))}
                    </Wrap>
                  </Stack>
                ))}


              </Stack>
            </HStack>

          </Stack>


        </ModalBody>

        {/* <ModalFooter>

          <Button onClick={onCancel}>
            Close
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  )


}

function OpCard({
  opInfo, onClick
}: {
  opInfo: {
    name: string
    op_type: string,
    description?: string

    onDelete?: () => Promise<any>
  }, onClick: () => void
}) {
  const [isLoading, setIsLoading] = useState(false)

  function getOpIcon(op: { tags?: string[], category: string, icon_id?: string } | any) {
    if (op.icon_id) {
      return <Image src={`https://icon-library.com/images/${op.icon_id}.png`} width="40px" height="30px" />
    }
    else if (op.tags?.includes("Saved")) {
      return <IconHeart size="30px" />
    }
    else if (op.tags?.includes("llm")) {
      return <IconSparkles size="40px" />
    }
    else if (op.tags?.includes("internet_search")) {
      return <IconWorldSearch size="40px" />
    }
    else if (op.category == "import") {
      return <LuImport size="40px" />
    }
    else if (op.category == "add_column") {
      return <IconTablePlus size="40px" />
    }
    else {
      return <IconSettings2 size="40px" />
    }
  }
  const [isDeleting, setIsDeleting] = useState(false)
  return <Card p="10px" m="10px" minWidth="340px" borderRadius="8px" boxShadow="md" cursor="pointer" onClick={(e) => {
    if ((e.target as HTMLElement).tagName.toLowerCase() !== 'button' && !(e.target as HTMLElement).closest('button')) {
      setIsLoading(true)
      onClick()
    }
  }} maxW="300px" border="1px solid lightgray" _hover={{ shadow: "xl" }} height="180px">
    <CardHeader p="0px">
      <HStack width="100" justify="space-between" align="start">
        <HStack align="start" p="8px">

          {getOpIcon(opInfo)}
          <Text fontWeight={600}>{opInfo.name}</Text>

        </HStack>
        {opInfo.onDelete && <Menu size="xs" placement="bottom-end">
          <MenuButton as={IconButton} icon={<IconX size="20px" />} variant="ghost" isLoading={isDeleting} />
          <MenuList p="0px">

            <MenuItem backgroundColor="pink.50" _hover={{ backgroundColor: "pink.100" }} fontWeight={900} fontSize="xs" icon={<IconTrash />} onClick={() => {
              setIsDeleting(true)
              opInfo.onDelete().finally(
                () => setIsDeleting(false)
              )
            }}>Confirm Delete</MenuItem>
          </MenuList>
        </Menu>}
      </HStack>
    </CardHeader>
    <CardBody p="8px 14px" alignContent="space-between" height="100%">
      <Stack justify="space-between" align="start" height="100%">
        <Text fontSize="xs" noOfLines={3}>{opInfo.description}</Text>
        <Button size="sm" isLoading={isLoading} >Apply</Button>
      </Stack>
    </CardBody>

  </Card>
}

const SidebarOption = ({ icon, category, count, isSelected, onSelect }: {
  icon?: React.ReactNode,
  category: string,
  count: number,
  isSelected: boolean,
  onSelect: () => void

}) => {



  return <HStack _hover={{ backgroundColor: "#fafafa", fontWeight: 600, p: "4px 4px" }}
    m="0px 4px" p="4px 8px" borderRadius={8} cursor="pointer" whiteSpace="nowrap"
    onClick={onSelect}
    fontWeight={isSelected ? 600 : undefined} backgroundColor={isSelected ? "gray.100" : undefined}
  >
    {icon || <Box width="20px"></Box>}
    <Text>{humanizeText(category)}</Text>
    <Tag>
      <TagLabel>{count}</TagLabel>
    </Tag>
  </HStack>
};

export { ListOpsSelectionDialog }