
import React, { useMemo } from 'react';
import { useApiFetch } from '../utils/useApiHook';
import { HStack, Spinner, Stack, Tbody, Th, Tr, Tag, Text, Thead, Table, Td } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { ActivityCard } from '../components/Inbox/activitiesCards';
import { Link } from 'react-router-dom';

interface TenantInfo {
    tenant_id: string;
    tenant_name: string;
}

interface TenantActivities {
    tenant: TenantInfo;
    activities: any[];

}

interface TenantCount {
    tenant: TenantInfo;
    count: number;
}

interface OverviewAnalytics {
    received: TenantActivities[];
    pending_verification: TenantActivities[];
    pending_tasks: TenantActivities[];
    accepted_connection_request: TenantActivities[];
    errors: TenantActivities[];
    sent: TenantActivities[];
    scheduled_to_send_today: TenantCount[];
    active_linkedin_accounts: TenantCount[];
}

const AccountsStats = ({ data }) => {
    const { limits, stats } = data || {};

    return (
        <Accordion allowMultiple allowToggle>
            {stats && Object.keys(stats).map((account) => {
                const lastDayStats = stats[account][stats[account].length - 1];
                return (
                    <AccordionItem key={account}>
                        <AccordionButton>
                            <Text flex="1" textAlign="left" fontSize="lg" fontWeight="bold">{account}</Text>
                            <HStack>
                                <Text fontSize="xs">Last day:</Text>
                                <Tag>Received: {lastDayStats.received}</Tag>
                                <Tag>Sent: {lastDayStats.sent}</Tag>
                                <Tag>Scheduled: {lastDayStats.scheduled}</Tag>
                            </HStack>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Stack border="1px solid #e2e8f0" borderRadius="md" p={4} mb={4}>
                                <Table variant="simple" size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th>Metric</Th>
                                            {stats[account].map((stat, index) => (
                                                <Th key={index}>{new Date(stat.date).toLocaleDateString()}</Th>
                                            ))}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>Received</Td>
                                            {stats[account].map((stat, index) => (
                                                <Td key={index}>{stat.received}</Td>
                                            ))}
                                        </Tr>
                                        <Tr>
                                            <Td>Sent</Td>
                                            {stats[account].map((stat, index) => (
                                                <Td key={index}>{stat.sent}</Td>
                                            ))}
                                        </Tr>
                                        <Tr>
                                            <Td>Scheduled</Td>
                                            {stats[account].map((stat, index) => (
                                                <Td key={index}>{stat.scheduled}</Td>
                                            ))}
                                        </Tr>
                                    </Tbody>
                                </Table>

                                {limits && limits[account] && (
                                    <Stack mt={4}>
                                        <Text fontSize="md" fontWeight="bold">Limits</Text>
                                        {Object.entries(limits[account]).map(([key, value]) => (
                                            <Text key={key}>{key}: {JSON.stringify(value)}</Text>
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        </AccordionPanel>
                    </AccordionItem>
                );
            })}
        </Accordion>
    );
};



const AdminOverview: React.FC = () => {

    const overviewDataFetch = useApiFetch<OverviewAnalytics>(`/support/overview`, { static: true })
    const allAccountsStats = useApiFetch<OverviewAnalytics>(`/analytics/accounts?_admin_view=true`, { static: true })
    const counts = useMemo(() => {
        if (!overviewDataFetch.data) return {};

        const data = overviewDataFetch.data;

        const countActivities = (activities: TenantActivities[]) =>
            activities.reduce((acc, tenantActivity) => acc + tenantActivity?.activities?.length, 0);

        return {
            received: countActivities(data.received),
            pending_verification: countActivities(data.pending_verification),
            pending_tasks: countActivities(data.pending_tasks),
            errors: countActivities(data.errors),
            accepted_connection_request: countActivities(data.accepted_connection_request),
            sent: countActivities(data.sent),
            scheduled_to_send_today: data.scheduled_to_send_today.reduce((acc, tenantCount) => acc + tenantCount.count, 0),
            active_linkedin_accounts: data.active_linkedin_accounts.reduce((acc, tenantCount) => acc + tenantCount.count, 0),
        };
    }, [overviewDataFetch.data])

    function tenantGroupAccordions(key: string, tenantActivities: TenantActivities[]) {

        return <Accordion allowMultiple allowToggle>
            {tenantActivities?.map((tenantActivity, i) => (
                <AccordionItem key={i}>

                    <AccordionButton>
                        <Text>{tenantActivity.tenant.tenant_name}</Text>
                        <Tag>{tenantActivity.activities?.length}</Tag>
                        <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel pb={4}>
                        <Stack>


                            {tenantActivity.activities.map((activity, ai) => (
                                <Link key={ai} to={`/inbox/${activity.contact_id}`} target='_blank'>
                                    <ActivityCard activity={activity} />
                                </Link>
                            ))}
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
            ))}

        </Accordion>
    }
    function tenantCounts(key: string, tenantCounts: TenantCount[]) {
        return <Stack>
            {tenantCounts?.map((tenantActivity) => (


                <HStack>
                    <Text>{tenantActivity.tenant.tenant_name}</Text>
                    <Tag>{tenantActivity.count}</Tag>

                </HStack>



            ))}

        </Stack>
    }
    if (overviewDataFetch.isLoading) return <Stack height="90vh" width="100%">
        <Spinner />
        <Text>
            Loading...</Text></Stack>

    return (
        <Stack>
            <Tabs>
                <TabList>
                    {!!overviewDataFetch.data?.pending_verification?.length && <Tab>Pending verification <Tag>{counts.received}</Tag></Tab>}
                    {!!overviewDataFetch.data?.pending_tasks?.length && <Tab>Pending tasks<Tag>{counts.pending_tasks}</Tag></Tab>}
                    <Tab>Received <Tag>{counts.received}</Tag></Tab>
                    <Tab>Sent<Tag>{counts.sent}</Tag></Tab>
                    <Tab>Errors<Tag>{counts.errors}</Tag></Tab>
                    <Tab>Accepted LinkedIn connections<Tag>{counts.accepted_connection_request}</Tag></Tab>
                    <Tab>Scheduled<Tag>{counts.scheduled_to_send_today}</Tag></Tab>
                    <Tab>All accounts stats</Tab>
                    <Tab>Active LinkedIn accounts<Tag>{counts.active_linkedin_accounts}</Tag></Tab>

                </TabList>

                <TabPanels>
                    {!!overviewDataFetch.data?.pending_verification?.length && <TabPanel>

                        {tenantGroupAccordions("pending_verification", overviewDataFetch.data?.pending_verification)}
                    </TabPanel>}
                    {!!overviewDataFetch.data?.pending_tasks?.length && <TabPanel>

                        {tenantGroupAccordions("pending_tasks", overviewDataFetch.data?.pending_tasks)}
                    </TabPanel>}
                    <TabPanel>
                        {tenantGroupAccordions("received", overviewDataFetch.data?.received)}
                    </TabPanel>
                    <TabPanel>
                        {tenantGroupAccordions("sent", overviewDataFetch.data?.sent)}
                    </TabPanel>
                    <TabPanel>
                        {tenantGroupAccordions("errors", overviewDataFetch.data?.errors)}
                    </TabPanel>
                    <TabPanel>
                        {tenantGroupAccordions("accepted_connection_request", overviewDataFetch.data?.accepted_connection_request)}
                    </TabPanel>
                    <TabPanel>
                        {tenantCounts("scheduled_to_send_today", overviewDataFetch.data?.scheduled_to_send_today)}
                    </TabPanel>
                    <TabPanel>
                        <AccountsStats data={allAccountsStats.data} />
                    </TabPanel>
                    <TabPanel>
                        {tenantCounts("active_linkedin_accounts", overviewDataFetch.data?.active_linkedin_accounts)}
                    </TabPanel>

                </TabPanels>
            </Tabs>

        </Stack>
    );
};

export default AdminOverview;