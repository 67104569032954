



import { useEffect, useMemo, useState } from "react";


import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag, WrapItem, Wrap, Link, ButtonGroup, Button, CloseButton } from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";

import GenericList from "../components/GenericList/genericList";

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import ContactCard from "../components/Inbox/ContactCard";
import { IconAddressBook, IconCheckbox, IconChevronDown, IconSend } from "@tabler/icons-react";
import { CampaignDetail } from "../components/Campaigns/analyticsDetail";
import { FaReply } from "react-icons/fa";


const MiniWidget = (props: { title: string, subtitle?: string, value: string, icon?: any }) => {
    return (
        <Stack textAlign="center" minWidth="100px" minH="85px" align="center" justify="end" border="1px solid lightgray" backgroundColor="white" p="5px 10px" borderRadius={6} spacing={props.subtitle ? 0 : undefined}>
            <Stack flexShrink={1} spacing={0}>

                <Text fontSize="sm" fontWeight="500" noOfLines={1}>{props.title}</Text>
                {(props.subtitle && <Text fontSize="xs" fontWeight="500" noOfLines={1}>{props.subtitle}</Text>)}
            </Stack>
            <HStack >
                <Box>
                    {props.icon}
                </Box>
                <Text fontSize="2xl" fontWeight="900">{props.value}</Text>
            </HStack>
        </Stack>
    )
}


export default function CampaignAnalytics() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { campaignId } = useParams()

    // useEffect(()=>{
    //     if (currentUser && searchParams.get("force")!="true" ){
    //         navigate("/home")
    //     }
    //     else{
    //         navigate("/login")
    //     }
    // },[currentUser])

    const getCampaignEndpoint = useApiFetch(`/analytics/campaigns/${campaignId}`, { shouldFetch: !!campaignId })


    const [selectedCampaign, setSelectedCampaign] = useState<any>(undefined)
    useEffect(() => {
        setSelectedCampaign(getCampaignEndpoint.data)
    }, [getCampaignEndpoint.data])
    // useEffect(() => {
    //     if (campaignId && !(selectedCampaign?.id == campaignId) || (selectedCampaign && !selectedCampaign.stats)) {
    //         getCampaignEndpoint.execute({ campaignId }).then((data) => {
    //             setSelectedCampaign(data)
    //         }).catch((err) => {
    //             setSelectedCampaign({ id: campaignId, name: "Campaign not found", stats: {} })
    //         })
    //     }
    //     else if (!campaignId) {
    //         setSelectedCampaign(undefined)
    //     }
    // }, [campaignId, selectedCampaign])


    return (
        <HStack align="stretch" justify="stretch" height="calc(100% - 5px)" maxW={["100vh", "100vw", "calc(100vw - 40px)"]} width="100%">
            <GenericList endpointPath="/analytics/campaigns" listTitle="Campaign analytics" primaryField="campaign"
                onSelected={(item) => { }}
                selectedId={campaignId}
                customCardRenderer={
                    (item: {
                        campaign_id?: string;
                        name: string;
                        contacts_count?: number;
                        contacted?: number;
                        canceled?: number;
                        accepted_invite?: number;
                        replied?: number;
                        last_timestamp?: Date;
                    }, index) => {

                        return (
                            <HStack key={index} border={item.campaign_id == campaignId ? "1px solid gray" : "1px solid lightgray"} borderRadius={8} p="6px"
                                bg={item.campaign_id == campaignId ? "white" : "gray.50"}
                                align="start" justify="space-between"
                                onClick={() => {
                                    navigate("/analytics/" + item.campaign_id)
                                setSelectedCampaign(item)
                                }}

                            >
                                <Stack>
                                    <HStack>
                                        <Text fontSize="sm"> {item.name}</Text>
                                    </HStack>
                                    <HStack justify="space-between" >
                                        <Stack flexGrow={1} fontSize="xs">
                                            {item.last_timestamp && <Text fontSize="xs" fontWeight={500}><Moment fromNow>{item.last_timestamp}</Moment></Text>}
                                            {/* <Text fontSize="2xl" textAlign="end" fontWeight={900}>{Math.round((item.goal_achieved / item.contacts) * 100)}%</Text> */}
                                        </Stack>

                                    </  HStack>

                                </Stack>
                                {!campaignId && (
                                    <HStack>
                                        <MiniWidget title="Contacts" value={item.contacts_count?.toString() || "0"} icon={<IconAddressBook />} />
                                        <MiniWidget title="Progress" subtitle={`${item.contacted || 0}/${(item.contacts_count - (item.canceled || 0) || 0)}`} value={(item.contacts_count - (item.canceled || 0)) ? (Math.round(100 * (item.contacted || 0) / (item.contacts_count - (item.canceled || 0))).toString() + "%") : (
                                            "-"
                                        )} />
                                        <MiniWidget title="Sent" value={item.contacted?.toString() || "0"} icon={<IconSend />} />
                                        {item.accepted_invite && <MiniWidget title="Accepted invite" value={item.accepted_invite?.toString() || "0"} icon={<IconCheckbox />} />}
                                        <MiniWidget title="Replied" value={item.replied?.toString() || "0"} icon={<FaReply />} />
                                    </HStack>
                                )}
                            </HStack>
                        )
                    }

                }
            />
            <Box width="0px" m="-4px -10px" top="0px" zIndex={5}>

                <CloseButton size="sm" onClick={() => { navigate("/analytics/") }} />
            </Box>
            {selectedCampaign && (
                <CampaignDetail selectedCampaign={selectedCampaign} />
            )}

        </HStack>
    );
}


