import { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, CardBody, CardFooter, CardHeader, ChakraProvider, Divider, Flex, HStack, Heading, IconButton, Image, Portal, SimpleGrid, Spinner, Stack, Switch, Tag, TagLabel, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAppContext } from "../appContext";

import "./homePage.css"

import { AutoUI } from "../components/AutoUI/AutoUI";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { IconWindowMaximize } from "@tabler/icons-react";
import { SearchFilter } from "../components/SearchFilter/searchFilter";
import { IconPlayerPlay } from "@tabler/icons-react";
import { IconTablePlus } from "@tabler/icons-react";
import { LuImport } from "react-icons/lu";
import { ListOpsSelectionDialog } from "../components/Lists/listOpsSelectionDialog";



export default function Test() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    // useEffect(()=>{
    //     if (currentUser && searchParams.get("force")!="true" ){
    //         navigate("/home")
    //     }
    //     else{
    //         navigate("/login")
    //     }
    // },[currentUser])


    function renderChat(active?:boolean){
      if (!active) 
          return  <></>
      else    {
          let wssUrl = process.env.REACT_APP_API_URL;
          let appId="899cbe3c-d646-4d2a-9974-6f31c5ceacc9"
          wssUrl = wssUrl.replace("http","ws")
          return (
             <></>
              // <ChatPopover
              //     triggerButton={<ChatFloatingButton/>}
              // >
              // <ChatComponent wsEndpoint={`${wssUrl}/${appId}/chat`}
              // messages={[
              //     {
              //         sentByMe: true,
              //         message: "Hi, I am a bot. I can help you with your queries",
              //         type:"chat"
              //     },
              //     {
              //       sentByMe: false,
              //       message: "Hi, certainkly, Here [^1] is an example of a chat message: ```\del()\```,\n[^1]: Big note.* [ ] to do\n* [x] done",
              //       type:"chat"
              //   },
              // ]}
              // />
              
              // </ ChatPopover>
          )
      }
     }
    const [value, setValue] = useState<any>({})
  const [modal, setModal] = useState<JSX.Element | null>(null)

    return (
        <Box  >
        <ListOpsSelectionDialog
          opTypes={[

            { name: "Get person info", category: "add_column", description: "Find info about company such as their linkedin / crunchbase profile and more", op_type: "data_enrichment", tags: ["Popular", "Recent"] },
            { name: "Get company info", description: "Find info about company such as their linkedin / crunchbase profile and more", op_type: "data_enrichment", tags: ["Popular", "Data enrichment"] },
            { name: "Modify column values", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", tags: ["Popular", "Data manipulation"] },
            { name: "Import from linkedin", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", tags: ["Popular", "Data manipulation"] },
            { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", tags: ["Popular", "Data manipulation"] },
            { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", tags: ["Popular", "Data manipulation"] },
            { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk Execute modification operation on a single column in bulk Execute modification operation on a single column in bulk Execute modification operation on a single column in bulk Execute modification operation on a single column in bulk", op_type: "data_enrichment", tags: ["Popular", "Data manipulation"] },
            { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", tags: ["Popular", "Data manipulation"] },
            { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", tags: ["Popular", "Data manipulation"] },
            { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", tags: ["Popular", "Data manipulation"] },


          ]}
          onOk={() => { }} onCancel={() => {
          setModal(null)
        }} />
          
          <AutoUI 
          value={value}
           onValueChange={setValue}
            schema={
                {
                    "title": "DataSourceDefinition",
                    "type": "object",
                    "properties": {
                      "name": {
                        "title": "Name",
                        "description": "Name of the datasource, that would help AI to figure out when to use it",
                        "maxLength": 256,
                        "example": "MyApp documentation",
                        "type": "string"
                      },
                      "description": {
                        "title": "Description",
                        "description": "Detailed description when to use the datasource, that would help AI and how to use it",
                        "maxLength": 1024,
                        "example": "Useful when user asks about how to do something in the MyApp",
                        "type": "string"
                      },
                      "type": {
                        "title": "Type",
                        "description": "Type of the implementation",
                        "maxLength": 256,
                        "type": "string"
                      },
                      "configuration": {
                        "title": "PlainTextDataSourceConfiguration",
                        "type": "object",
                        "properties": {
                          "content_text": {
                            "title": "Content Text",
                            "description": "Text information to use as a reference source of data",
                            "maxLength": 100000,
                            "type": "string"
                          },
                          "content_format": {
                            "title": "Content Format",
                            "description": "Format of the content",
                            "default": "plain_text",
                            "example": "plain",
                            "one_of": [
                              "plain_text",
                              "markdown"
                            ],
                            "type": "string"
                          }
                        }
                      }
                    },
                    "required": [
                      "name",
                      "description",
                      "type",
                      "configuration"
                    ],
                    "definitions": {
                      "BaseModel": {
                        "title": "BaseModel",
                        "type": "object",
                        "properties": {}
                      }
                    }
                  }
                
            }
            />
        </Box>
    )
}
