import React, { useEffect, useMemo, useState } from 'react';
import CanvasFlowPage from './PageTypes/canvas';
import FormFlowPage from './PageTypes/form';
import { PageDefinition, FlowSession } from './model';
import { Spinner, Stack, useToast } from '@chakra-ui/react';
import { MockFlowSessionService } from './FlowSessionService';
import { FlowSessionService, FlowSessionServiceType } from './FlowSessionService';
import { Skeleton, SkeletonText } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom';
import { useFlow } from './flowHook';


const OnboardingPage: React.FC = () => {
    const {
        flowSession,
        currentPageState,
        updatePageState: setCurrentPageState,
        isUpdating,
        updatingMode,
        nextPage,
        previousPage,
        withUpdateIndicator,
        handleErrors,
        setFlowSession,
        flowSessionService // Destructure flowSessionService from the hook
    } = useFlow("onboarding");

    function renderPage(page: PageDefinition) {
        const params = {
            page: flowSession?.currentPage,
            onBack: previousPage,
            onNext: nextPage,
            currentPageIndex: flowSession?.currentPageIndex,
            totalPages: flowSession?.totalPages,
            currentPageState,
            setCurrentPageState,
            isUpdating,
            updatingMode,
            onFeedback: (feedback) => withUpdateIndicator("generate", flowSessionService.regenerate(flowSession.currentPage.id, feedback).then(setFlowSession).catch(handleErrors))
        };
        switch (page?.type) {
            case "canvas":
                return <CanvasFlowPage {...params} />;
            case "form":
                return <FormFlowPage {...params} />;
            default:
                return <Stack>
                    <h1>Unknown page type</h1>
                </Stack>
        }
    }

    return (
        <Stack width="100%" height="calc(100vh - 50px)" maxHeight="calc(100vh - 50px)" spacing={10} justify="stretch">
            {flowSession ? renderPage(flowSession.currentPage) : (
                <Stack width="60%" p="30px">
                    <Skeleton height='40px' width="60%" />
                    <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='20px' />
                </Stack>
            )}
        </Stack>
    );
};

export default OnboardingPage;