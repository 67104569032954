import React, { useState } from 'react';
import FlowPageProps from './pageProps';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { AutoUI, GeniouslyThemeProvider, MarkdownContent } from 'geniously-chat-ui';
import { EditableField } from '../../EditableField/editableField';
import FlowPageWrapper from './pageWrapper';


const FormField = ({ label, value, onApply, required, fieldSchema, size, onChange }: {
    label: string;
    value: string;
    onApply: () => void;
    fieldSchema?: JSONSchema,
    required?: boolean; size?: "sm" | "lg";
    onChange: (val: any) => any
}) => {
    return (
        <Stack minH="50px">
            <Text>{label}</Text>
            <EditableField value={value} onApply={onApply} required={required} size={size} onChange={onChange} placeholder={fieldSchema?.example || fieldSchema?.default} />
        </Stack>
    );
}

const FormFlowPage: React.FC<FlowPageProps> = (props: FlowPageProps) => {
    const [formData, setFormData] = useState(props.page.form?.data || {});

    const handleFieldChange = (fieldKey: string, value: any) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldKey]: value,
        }));
    };

    return (
        <FlowPageWrapper pageProps={props}>

            <Stack p="10px">
                <GeniouslyThemeProvider>

                    <AutoUI size="xl" value={props.currentPageState || props.page.form?.data} schema={props.page.form?.data_schema}
                        showOnlySchemaFields
                        onValueChange={(val) => {
                            if (JSON.stringify(val) !== JSON.stringify(props.currentPageState)) {
                                props.setCurrentPageState(val)
                            }
                        }} />

                </GeniouslyThemeProvider>
            </Stack>
        </FlowPageWrapper>
    );
};

export default FormFlowPage;