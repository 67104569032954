import React from 'react';
import { HStack, Stack, Menu, MenuButton, MenuList, MenuItem, Button, Text, IconButton } from '@chakra-ui/react';
import { IconChevronDown, IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

interface PagingComponentProps {
    pageNo: number;
    totalCount: number;
    onPageChange: (page: number) => void;
    pageSize?: number;
    size?: "sm" | "md"
}

const PagingComponent: React.FC<PagingComponentProps> = ({ pageNo, totalCount, onPageChange, pageSize = 100, size = "sm" }) => {
    return (
        <HStack justify="end" alignSelf="end">
            <Stack align={size == "sm" ? "center" : "end"} spacing={size == "sm" ? 5 : 0} direction={size == "sm" ? "row-reverse" : "column"} >
                {totalCount && <Menu>
                    <MenuButton as={Button} size="xs" rightIcon={<IconChevronDown size="15px" />}>
                        {100 * (pageNo - 1) + 1}-{100 * (pageNo) > totalCount ? totalCount : 100 * (pageNo)}
                    </MenuButton>
                    <MenuList>
                        {Array.from({ length: Math.floor(totalCount / 100) + 1 }, (_, i) => i * 100).map((startRange) => (
                            <MenuItem fontSize="xs" justifySelf="start" key={startRange} onClick={() => onPageChange((startRange / 100) + 1)}>
                                {startRange} - {startRange + 100 > totalCount ? totalCount : startRange + 100}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>}
                <Text fontSize="xs">Total rows: {totalCount}</Text>
            </Stack>
            <IconButton isDisabled={pageNo <= 1} size={size} aria-label="Previous page" icon={<IconChevronLeft />} onClick={() => pageNo > 1 && onPageChange(pageNo - 1)} />
            <IconButton
                isDisabled={pageNo >= Math.ceil(totalCount / pageSize)}
                size={size}
                aria-label="Next page" icon={<IconChevronRight />} onClick={() => onPageChange(pageNo + 1)} />
        </HStack>
    );
};

export default PagingComponent;